import { Dialog } from "@mui/material";

const SuiteDialog = ({open, width, height, onClose, body}) => {
    return <Dialog
    maxWidth="85vw"
    open={open}
    PaperProps={{
        style: {
            minHeight: height ?? "fit",
            width: width,
            backgroundColor: "#0F1011",
            color: "#fff",
            boxShadow: "none",
            borderRadius: "12px",
            border: "1px solid #2C2E33",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
                display: "none",
            }
        },
    }}
    {...{ onClose }}
>
    {body}
</Dialog>
}

export default SuiteDialog;