import { useEffect, useState } from 'react';
import { DevzeryLogo, Globe, SwaggerIcon, SettingsIcon, LogOutIcon } from "../../icons";
import './Navbar.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../AuthContext';


export const Header = ({ projects, username, logout }) => {

  const { workspaces, getworkspaces, setWorkspacesContext, workspaceContext } = useAuth();

  useEffect(() => {
    getworkspaces();
  }, []);

  useEffect(() => {
    setSelectedWorkspace(workspaceContext?._id);
  }, [workspaceContext]);


  const [selectedWorkspace, setSelectedWorkspace] = useState(workspaceContext);
  const [selectedProject, setSelectedProject] = useState(projects[0]);
  const navigate = useNavigate();
  const location = useLocation();
  const {pathname} = location;  

  const handleWorkspaceChange = (event) => {
    const selectedWorkspaceId = event.target.value;
    setSelectedWorkspace(selectedWorkspaceId);
    const selectedWorkspace = workspaces.find(workspace => workspace._id === selectedWorkspaceId);
    setWorkspacesContext(selectedWorkspace);
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
    await logout();
    navigate('/login');
  };


  return (
    <header className="bg-black px-8 py-4 flex-shrink-0 border-b border-[#2C2E33]">
      <div className="flex items-center justify-between">
        {/* Logo and Workspace */}
        <div className="flex items-center gap-4">
          <div className=""><img src={DevzeryLogo} alt="" /></div>
          {/* Workspace Dropdown */}
          <select
            value={selectedWorkspace}
            onChange={handleWorkspaceChange}
            className="bg-black text-gray-400 rounded-md px-2 py-1 text-sm border border-gray-700 h-8"
            disabled={workspaces.length <= 1}
          >
            {workspaces.map((workspace, index) => (
              <option key={index} value={workspace._id}>
                {workspace.name}
              </option>
            ))}
          </select>
          {/* Project Dropdown */}
          {/* <select
           value={selectedProject}
           onChange={(e) => setSelectedProject(e.target.value)}
           className="bg-black text-gray-400 rounded-md px-2 py-1 text-sm border border-gray-700 h-8"
           disabled={projects.length <= 1}
         >
           {projects.map((project, index) => (
             <option key={index} value={project}>
               {project}
             </option>
           ))}
         </select> */}
        </div>
        {/* User Info and Icons */}
        <div className="flex items-center gap-4 text-dashboard-text-graylight">
          <span className={"p-1.5 cursor-pointer rounded-md flex justify-center items-center hover:bg-dashboard-dark-600 hover:text-white "+ (pathname==='/base-url' ? 'bg-dashboard-dark-600 text-white' : 'text-dashboard-text-graylight')}>
            <Globe className='w-5 h-5' onClick={() => navigate('/base-url')} />
          </span>
          <span className="text-dashboard-text-graylight p-1.5 cursor-pointer rounded-md flex justify-center items-center hover:bg-dashboard-dark-600 hover:text-white">
            <SwaggerIcon className="w-5 h-5" />
          </span>
          <span className={"p-1.5 cursor-pointer rounded-md flex justify-center items-center hover:bg-dashboard-dark-600 hover:text-white "+ (pathname==='/settings' ? 'bg-dashboard-dark-600 text-white' : 'text-dashboard-text-graylight')}>
            <SettingsIcon className="w-5 h-5" onClick={() => navigate('/settings')} />
          </span>
          <div className="flex items-center justify-between gap-4 text-dashboard-text-graylight rounded-md px-2 text-sm border border-gray-700 h-8">
            <span>{username}</span>
            <LogOutIcon
              className="h-4 w-4 cursor-pointer"
              onClick={handleLogout}
              role="button"
              title="Logout"
            />
          </div>
        </div>
      </div>
    </header>
  );
};
