import "./SignIn.css";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import {
  useGoogleLogin,
} from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../CustomButton/CustomButton";
import { fetchTC } from "../../Actions/tcActions";
import { useAuth } from '../../AuthContext';
import Loader from "../Loader/Loader";
import LoginGif from '../../icons/login-gif.gif'
import Logo from '../../icons/logoShort.svg'
import SnackbarComp from "../Snackbar/Snackbar";

/*

SignIn component that renders the sign in/sign up page of the application.
It is the first page that the user sees when they visit the application.
The SignIn component uses the useSelector and useDispatch hooks from react-redux to access the state and dispatch actions.
The SignIn component uses the useState hook to manage the state of the email, password, rememberMe,
 snackbarOpen, alertSeverity, alertMessage, showIframe, and iframeSrc.

It uses the useEffect hook to check if the user is new and redirect them to the chatbot.
It uses the useEffect hook to check if the user is not new and redirect them to the test-suite.
It renders a form for the user to enter their email and password.
It renders buttons for the user to sign in with Google and GitHub.
It renders a snackbar to display alerts to the user.


*/

export const SignIn = (join=false) => {
  const { login, user, googleSSO, error, githubSSO, loading } = useAuth();

  const emailId = join?.email ? join?.email : "";
  join = join?.join ? join?.join : false;


  // console.log('User', user)
  // Update the state of the email,password and rememberMe
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);

  // Change the state of the snackbarOpen, alertSeverity and alertMessage
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  // Change the state of the showIframe and iframeSrc
  const [showIframe, setShowIframe] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('Submit Clicked')
    try {
      await login(email, password, rememberMe); // Dispatch the loginUser action
    } catch (error) {
      // On error
      setAlertSeverity("error");
      setAlertMessage("Login failed. Please check your credentials.");
      setSnackbarOpen(true);
    }
  };
  const googleLogin = useGoogleLogin({
    onSuccess: async (credentialResponse) => {
      const token = credentialResponse.access_token;
      const expiresIn = credentialResponse.expires_in;
      const currentTime = new Date().getTime();
      const expirationTime = currentTime + expiresIn * 1000;
      try {
        await googleSSO(token, expirationTime);
      } catch (error) {
        if (error === "User already exists, please login with your email and password.") {
          setAlertSeverity("error");
          setAlertMessage(error);
          setSnackbarOpen(true);
        }
      }
    },
    onError: () => {
      setAlertSeverity("error");
      setAlertMessage("Login failed. Please check your credentials.");
      setSnackbarOpen(true);
    },
  });

  const gitHubSignIn = () => {
    window.location.href = `https://github.com/login/oauth/authorize?client_id=Ov23licmFeL55uwkUjin`;
  };

  // If new user, redirect the user to the chatbot
  // let storedUser = user ? user : localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  useEffect(() => {
    // // console.log('user', storedUser)
    if( user && join )
    {
      navigate("/test-suite")
    }
    if (user && user.orgDetails && user.isNew) {
      navigate("/integrate-sdk");
    } else if (user && user.isNew && !user.orgDetails) {
      setAlertSeverity("success");
      setAlertMessage("Login successful!");
      setSnackbarOpen(true);
      navigate("/org-details");
    } else if (user && user.isNew === false) {
      // console.log(user.isNew);
      setAlertSeverity("success");
      setAlertMessage("Login successful!");
      dispatch(fetchTC());
      setSnackbarOpen(true);
      navigate("/test-suite");
    }
  }, [user, user?.isNew, join]);

  // If user is not new then redirect the user to the test-suite
  useEffect(() => {
    // console.log('error', error)
    if (
      error !== null &&
      error !== "Error validating token" &&
      error !== "User not found" &&
      error !== "Network Error" &&
      error !== "Request failed with status code 401" &&
      error !== "User already exists, please login with your email and password." &&
      error !== "User validation failed: password: Password must be at least 8 characters long"
    ) {
      setAlertSeverity("error");
      setAlertMessage("Login failed. Please check your credentials.");
      setSnackbarOpen(true);
    } else if (error !== null) {
      if (error === "User validation failed: password: Password must be at least 8 characters long") {
        setAlertSeverity("error");
        setAlertMessage("Password must be at least 8 characters long");
        setSnackbarOpen(true);
      } else {
        setAlertSeverity("error");
        setAlertMessage(error);
        setSnackbarOpen(true);
      }
    }
  }, [user, error]);

  useEffect(() => {
    const querystring = window.location.search;
    const params = new URLSearchParams(querystring);
    const code = params.get("code");
    setEmail(emailId);
    if (code) {
      githubSSO(code);
    }
    if (user && user.githubId) {
      dispatch({
        type: "googleTokenValidationSuccess",
      });
    }
  }, []);

  return showIframe === false ? (loading ? <Loader loading={loading} /> :
    <>
      <img className="w-screen h-full absolute -translate-x-1/4 z-0" src={LoginGif} alt="" />
      <div className="grid grid-cols-10 w-full overflow-hidden bg-dashboard-dark-700">
        <div className="col-span-3 overflow-hidden w-full h-[100vh] relative">
          <div className="loginGradient " />
          <div className="w-full absolute h-full top-0 left-0 bg-[#14141E]/30 z-15 " />
          <div className="absolute top-8 left-8 z-20"><img src={Logo} /></div>
        </div>
        <div className="col-span-4 text-white py-24 pl-24 pr-12 z-10 bg-dashboard-dark-700">
          <div className="font-bold text-h1">Signup or Login</div>
          <div className="text-[#B0B0B0]">Start using our product today</div>
          <div className="grid grid-cols-2 gap-4 mt-12 max-w-[432px]">
            <div className="gitSignIn">
              <CustomButton
                sso="Google"
                icon="Google"
                color="#FFFFFF"
                onClick={googleLogin}
              />
            </div>
            <div className="googleSignIn">
              <CustomButton
                sso="GitHub"
                icon="GitHub"
                color="#333333"
                onClick={gitHubSignIn}
              />
            </div>
          </div>
          <div className="my-6 flex items-center justify-start max-w-[432px]">
            {/* <div className="or-line"></div> */}
            <div className="w-[48%] h-[1px] translate-y-[3px] bg-[#2C2E33]" />
            <div className="px-2">or</div>
            <div className="w-[48%] h-[1px] translate-y-[3px] bg-[#2C2E33]" />
          </div>
          <div className="max-w-[432px]">
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label htmlFor="outlined-required" className="compulsoryInput">
                  Email <span className="text-[#F37BB3]">*</span>
                </label>
                <br></br>
                <input
                  className="w-full input mt-2"
                  disabled={join}
                  value={email}
                  required
                  placeholder="mail@website.com"
                  id="outlined-required"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div>
                <label htmlFor="outlined-required" className="compulsoryInput">
                  Password <span className="text-[#F37BB3]">*</span>
                </label>
                <br></br>
                <input
                  required
                  className="w-full mt-2 input"
                  value={password}
                  id="outlined-required"
                  placeholder="Min. 8 character"
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
              <div className="flex justify-between items-center my-4">
                <div className="rememberMe">
                  <input
                    style={{ marginRight: "5px" }}
                    type="checkbox"
                    id="rememberMe"
                    name="rememberMe"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <label htmlFor="rememberMe">Remember Me</label>
                </div>
                <div className="forgotPass">
                  <a href="/reset-password">Forgot Password?</a>
                </div>
              </div>
              <Button
                variant="contained"
                fullWidth
                type="submit"
                className="loginButton"
                sx={{
                  width: "100%",
                  padding: "14px 16px",
                  height: "48px",
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  marginTop: "20px",
                  borderRadius: "6px",
                  border: "1px solid #E27AAB",
                  backgroundColor: "#E27AAB",
                  ":hover": {
                    backgroundColor: "#DD6B9F",
                    borderColor: "#FA8DC0",
                  },
                  textTransform: "unset",
                }}
              >
                Login
              </Button>
            </form>
            <div className="text-dashboard-text-graylight text-wrap mt-4">
              By continuing, you are agreeing to our <span className="text-dashboard-text-pink">Terms of Service</span>,
              <span className="text-dashboard-text-pink">Subscription Plan</span> and <span className="text-dashboard-text-pink">Privacy Policy</span>.
            </div>
          </div>
          <SnackbarComp open={snackbarOpen} severity={alertSeverity} message={alertMessage} handleClose={() => setSnackbarOpen(false)} />

        </div>
        <div className="bg-dashboard-dark-700 col-span-3 z-10" />
      </div>
    </>
  ) : (
    <iframe
      src={iframeSrc}
      style={{ width: "100%", height: "100vh", border: "none" }}
      title="Devzery"
    />
  );
};
