import { MdOutlineArrowBackIos } from "react-icons/md";
import APIItem from "./APIItem/APIItem";
import { useNavigate } from "react-router-dom";

const TestSteps = ({ title, subtitle, data, type}) => {
    const navigate = useNavigate();

    return <div className="py-4 w-full">
        <div className="flex gap-6 text items-center mb-8 bg-[#141516] px-4 py-2">
            <MdOutlineArrowBackIos className="#B0B0B0 cursor-pointer" onClick={() => navigate(-1)} />
            <span className="text-[#A0A0A0]">{title}</span>
        </div>
        <div className="py-4">
            <div className="text-white px-4">{subtitle}</div>
            <div className="my-6 overflow-y-scroll max-h-[80vh] no-scrollbar">
                {data?.length > 0 && data?.sort((a, b) => a.step - b.step).map((api, index) => <APIItem api={api} type={type} />)}
            </div>
        </div>
    </div>
}

export default TestSteps;