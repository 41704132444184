import SettingsHeading from '../Components/SettingsHeading';
import { Panel } from 'react-resizable-panels';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../../components/SearchBar/SearchBar';
import { useAuth } from '../../../AuthContext';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWorkspaceUsers } from '../../../Actions/workspaceActions';
import { SlOptions } from "react-icons/sl";
import { memo } from 'react';
import { Snackbar, Alert } from '@mui/material';


const getStatusStyles = (status) => {
  const styles = {
    Active: {
      container: "bg-dashboard-dark-201 text-dashboard-text-green",
      dot: "bg-dashboard-dark-203"
    },
    Inactive: {
      container: "bg-dashboard-dark-200 text-dashboard-text-red",
      dot: "bg-dashboard-dark-202"
    },
    default: {
      container: "bg-dashboard-dark-600 text-dashboard-text-gray",
      dot: "bg-dashboard-text-gray"
    }
  };


  return styles[status] || styles.default;
};


const StatusBadge = memo(({ status }) => {

    if (!status) {
        status = 'Active';
    } 

    if (status === 'active') {
        status = 'Active';
    } else if (status === 'inactive') {
        status = 'Inactive';
    }

  const styles = getStatusStyles(status);


  return (
    <span className={`px-2 py-1.5 rounded-md text-sm ${styles.container}`}>
      <span className={`inline-block w-2.5 h-2.5 rounded-full mr-2 ${styles.dot}`}></span>
      {status}
    </span>
  );
});

const TeamSettings = () => {

    // const { workspaceUsers } = useSelector(state => state.workspace);

    const dispatch = useDispatch()

    const { workspaceUsers, loading, error } = useSelector(state => state.workspace);


    const { workspaceContext } = useAuth();

    const { t } = useTranslation();

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const [searchTerm, setSearchTerm] = useState('');

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleInviteSuccess = () => {
        setSnackbar({
            open: true,
            message: 'Invites sent successfully',
            severity: 'success'
        });
        dispatch(fetchWorkspaceUsers(workspaceContext._id));
    };

    const handleInviteError = (error) => {
        setSnackbar({
            open: true,
            message: error || 'Failed to send invites',
            severity: 'error'
        });
    };

    useEffect(() => {
        dispatch(fetchWorkspaceUsers(workspaceContext._id));
        console.log(workspaceUsers);
    }, []);

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const filteredUsers = workspaceUsers.filter(user => {
        const searchLower = searchTerm.toLowerCase();
        return (
            user.name.toLowerCase().includes(searchLower) ||
            user.email.toLowerCase().includes(searchLower)
        );
    });

    return (
        <div>
            <Panel>
                <SettingsHeading 
                    variant='team'
                    onInviteSuccess={handleInviteSuccess}
                    onInviteError={handleInviteError}
                />
            </Panel>
            <Panel>
                <div className='flex flex-col gap-4 mb-6'>
                    <div className='text-h4'>{t("settings.manage_members")}</div>
                    <SearchBar
                        search={searchTerm}
                        handleSearch={handleSearch}
                        placeholder="Search team members..."
                        className="w-[384px]"
                    />
                </div>
            </Panel>
            <Panel>
                <div className='flex flex-col gap-16 text-h4 mt-10 mb-5'>
                    Team
                </div>
                <div className='flex flex-col gap-4'>
                    {filteredUsers.map((user) => (
                        <div key={user._id} className='flex justify-between p-2'>
                            <div className='flex gap-2'>
                                <img src={`https://via.placeholder.com/150?text=${user.name.charAt(0)}`} alt={user.name} className='w-10 h-10 rounded-full' />
                                <div className='flex flex-col'>
                                    <span className='font-semibold'>{user.name}</span>
                                    <span className='text-sm text-gray-500'>{user.email}</span>
                                </div>
                            </div>
                            <div className='w-[88px] h-[28px] p-[6px] gap-[10px] rounded-[6px] bg-[#112313] flex items-center justify-center'>
                                <StatusBadge status={user.status} />
                            </div>
                            <select className='w-[98px] h-[28px] p-[6px] gap-[10px] rounded-[6px] bg-dashboard-dark-600'>
                                <option value="member">Member</option>
                                <option value="admin">Admin</option>
                                <option value="manager">Manager</option>
                            </select>
                            <SlOptions />
                        </div>
                    ))}
                </div>
            </Panel>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert 
                    onClose={handleSnackbarClose} 
                    severity={snackbar.severity}
                    variant="filled"
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default TeamSettings;