import JSONPretty from 'react-json-pretty';

const JSONPrettyComp = ({ data }) => {
    return <JSONPretty
    style={{ overflowY: "scroll", height: "100%" }}
    id="json-pretty"
    themeClassName="text-sm"
    keyStyle="color: #E45799;"
    data={data}
  ></JSONPretty>
}

export default JSONPrettyComp