import { useState } from "react";
import { RiEditBoxLine, RiSaveLine } from "react-icons/ri";

const Version = () => {
    const [version, setVersion] = useState('Version-1.2.5')
    const [versionDisabled, setVersionDisabled] = useState(true)

    return <div className="rounded-md p-3 border border-[#2C2E33] flex justify-between items-center w-full">
    <input disabled={versionDisabled} value={version} onChange={(e) => setVersion(e.target.value)} className="bg-inherit w-full focus:outline-none active:outline-none" />
    <span className="bg-dashboard-dark-600 rounded p-1 text-dashboard-text-white cursor-pointer" onClick={() => setVersionDisabled(!versionDisabled)}>
        {versionDisabled ? <RiEditBoxLine size={14} /> : <RiSaveLine size={14} />}
    </span>
</div>
}

export default Version;