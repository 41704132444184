import { useState, useRef, Fragment } from "react";
import HeaderRow from "./HeaderRow/HeaderRow";
import { getMethodClasses } from "../../../utils/ui";
import { RiSubtractFill } from "react-icons/ri";
import { PiPlus } from "react-icons/pi";
import { Popover } from "@mui/material";
import { AiOutlineImport } from "react-icons/ai";


const Header = ({ addedApis, currentApi, setAddedApis, headers, setHeaders }) => {
    const [newKey, setNewKey] = useState(null)
    const [value, setValue] = useState(null)
    const [importAPI, setImportAPI] = useState('default');
    const [popOpen, setPopOpen] = useState({ value: false, ind: 0, headerKey: "", headerKeys: {} });
    const [selected, setSelected] = useState({ value: '', ind: 0 })

    const headerEl = useRef(null);

    const renderHeaders = () => {
        let headerArr = [];
        if (headers && Object.keys(headers).length > 0) {
            let formattedHeaders = Object.keys(headers).map(key => [key, headers[key]])
            formattedHeaders.map((header, index) => headerArr.push(<HeaderRow apiid={currentApi?.id} setNewKey={setNewKey} setValue={setValue} popOpen={popOpen} setPopOpen={setPopOpen} key={index} ind={index} headerKey={header[0]} val={header[1]} headers={formattedHeaders} setHeaders={setHeaders} />))
        }
        return headerArr;
    }

    const handleImport = () => {
        if (popOpen.headerKey) {
            let ind = addedApis.findIndex((api) => api.id === currentApi?.id);
            let apiTemp = { ...addedApis[ind], headers: { ...headers, [popOpen.headerKey]: selected.value[1] } }
            setHeaders({ ...headers, [popOpen.headerKey]: selected.value[1] });
            addedApis[ind] = apiTemp;
            setAddedApis(addedApis);
        }
        setPopOpen({ ...popOpen, value: false, headerKeys: { ...popOpen.headerKeys, [currentApi?.id]: { ...popOpen.headerKeys[currentApi?.id], [popOpen.headerKey]: addedApis[importAPI] } } });
    }

    const handleUpdateHeaderDependency = () => {
        let temp = { ...headers }
        temp[newKey] = value
        if (newKey !== popOpen.headerKey) {
            delete temp[popOpen.headerKey]
        }
        let ind = addedApis.findIndex((api) => api.id === currentApi?.id);
        let apiTemp = { ...addedApis[ind], headers: temp }
        addedApis[ind] = apiTemp;
        setAddedApis(addedApis);
        setHeaders(temp)
        setPopOpen({ ...popOpen, value: false })
    }

    return <div className="rounded border border-[#2C2E33] mb-6">
        <div className="border-b border-[#2C2E33]"><div className="border-b-2 border-[#E27AAB] p-2.5 max-w-fit">Headers</div></div>
        <div className="max-h-[20vh] overflow-y-auto no-scrollbar">
            <div className="grid grid-cols-12 border-b border-[#2C2E33]" ref={headerEl}>
                <div className="col-span-1" />
                <div className="col-span-4 px-3 py-2 border-x border-[#2C2E33]">Key</div>
                <div className="col-span-7 px-3 py-2">Value</div>
            </div>
            {renderHeaders()}
        </div>
        <Popover
            open={popOpen.value}
            onClose={() => setPopOpen({ ...popOpen, value: false })}
            anchorEl={headerEl.current}
            className="no-scrollbar"
            slotProps={{
                paper: {
                    style: {
                        width: "50vw",
                        backgroundColor: "#0F1011",
                        color: "#B0B0B0",
                        boxShadow: "none",
                        borderRadius: "12px",
                        border: "1px solid #3D3D3D",
                        padding: "1rem",
                        maxHeight: "50vh",
                        overflowY: "scroll",
                        display: "flex",
                        flexDirection: "column",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                        "&::WebkitScrollbar": {
                            display: "none",
                        }
                    },
                }
            }}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            anchorPosition={
                {
                    top: 0,
                    left: -500
                }
            }
        >
            {(popOpen?.headerKeys[currentApi?.id] && popOpen?.headerKey in popOpen?.headerKeys[currentApi?.id]) ? <Fragment>
                <div
                    onClick={() => setPopOpen({ ...popOpen, value: false })}
                    className="origin-center rotate-45 cursor-pointer text-[#B0B0B0] self-end"
                >
                    <PiPlus size={20} />
                </div>
                <div className="text-[#E27AAB] font-semibold">Dependency Value</div>
                <div className="grid grid-cols-5 gap-4 justify-between items-center">
                    <span>Key</span>
                    <input value={newKey} onChange={(e) => setNewKey(e.target.value)} className="col-span-4 bg-[#0F1011] rounded-md border border-[#2C2E33] w-full px-3 py-2 focus:outline-none active:outline-none" placeholder="currency" />
                </div>
                <div className="grid grid-cols-5 gap-4 justify-between items-center my-2">
                    <span>Value</span>
                    <input value={value} onChange={(e) => setValue(e.target.value)} className="col-span-4 bg-[#0F1011] rounded-md border border-[#2C2E33] w-full px-3 py-2 focus:outline-none active:outline-none" placeholder="currency" />
                </div>
                <div className="grid grid-cols-5 gap-4 justify-between items-center mb-4">
                    <span>API</span>
                    <div className="col-span-4 bg-[#0F1011] rounded-md border border-[#2C2E33] w-full px-3 py-2 flex gap-3 items-center">
                        <div className=""><span className={"rounded-full px-4 py-1 text-xs " + getMethodClasses(popOpen.headerKeys[currentApi?.id][popOpen.headerKey]?.method)}>{popOpen.headerKeys[currentApi?.id][popOpen.headerKey]?.method}</span></div>
                        <span>{popOpen.headerKeys[currentApi?.id][popOpen.headerKey]?.name}</span>
                    </div>
                </div>
                <div className={"px-3 py-1 text-white rounded-md max-w-fit self-end justify-self-end " + (importAPI === 'default' ? "bg-[#222222]" : "bg-[#D9509B] hover:bg-[#C0408C] cursor-pointer")} onClick={importAPI === 'default' ? () => console.log("") : handleUpdateHeaderDependency}>Update Dependency</div>
            </Fragment> : <Fragment>
                <div
                    onClick={() => setPopOpen({ ...popOpen, value: false })}
                    className="origin-center rotate-45 cursor-pointer text-[#B0B0B0] self-end"
                >
                    <PiPlus size={20} />
                </div>
                <div className="font-semibold my-2">Select API</div>
                <select className="bg-[#0F1011] px-3 py-2 py-0.5 rounded-md border border-[#2C2E33]" value={importAPI} onChange={(e) => setImportAPI(e.target.value)}><option disabled="disabled" value={'default'}>Select an API</option>{addedApis.map((api, index) => index < addedApis.indexOf(currentApi) ? <option value={index}>{api.name}</option> : null)}</select>
                {importAPI !== 'default' ? <div className="font-semibold my-2">Headers</div> : null}
                {importAPI !== 'default' ? <div className="rounded-md bg-[#080808] border border-[#2C2E33]">
                    <div className="grid grid-cols-3">
                        <div className="px-3 py-1.5 col-span-1 border-b border-r border-[#2C2E33]">Key</div>
                        <div className="px-3 py-1.5 col-span-2 border-b border-[#2C2E33]">Value</div>
                    </div>
                    {addedApis[importAPI].headers && Object.entries(addedApis[importAPI].headers).map((el, ind) => <div>
                        <div className={"bg-[#080808] grid grid-cols-3 group" + (ind === selected.ind ? " bg-[#1D1E20]" : "")} onClick={() => setSelected({ value: el, ind })}>
                            <div className="px-3 col-span-1 py-1.5 border-b border-r border-[#2C2E33] overflow-x-auto no-scrollbar">{el[0]}</div>
                            <div className="col-span-2 border-b border-[#2C2E33] flex justify-between gap-2 items-center">
                                <div className="px-3 py-1.5 text-wrap break-words overflow-x-auto no-scrollbar">{typeof (el[1]) === 'string' || typeof (el[1]) === 'boolean' || typeof (el[1]) === 'number' ? el[1] : JSON.stringify(el[1])}</div>
                                <div className="hidden group-hover:block mr-2">{ind === selected.ind ? <RiSubtractFill className="bg-[#494949] p-0.5 flex items-center justify-center rounded-sm" size={22} /> : <AiOutlineImport className="bg-[#494949] p-1 cursor-pointer flex items-center justify-center rounded-sm" size={20} />}</div>
                            </div>
                        </div>
                    </div>)}
                </div> : null}
                <div className={"px-3 py-1 text-white rounded-md max-w-fit self-end justify-self-end mt-4 " + (importAPI === 'default' ? "bg-[#222222]" : "bg-[#D9509B] hover:bg-[#C0408C] cursor-pointer")} onClick={importAPI === 'default' ? () => console : handleImport}>Import</div>
            </Fragment>}

        </Popover>
    </div>
}

export default Header;