import { Panel } from "react-resizable-panels";
import { LuTrash } from "react-icons/lu";
import { useAuth } from "../../../AuthContext";
import { PiPlus } from "react-icons/pi";

const Headings = ({
    children,
    variant = 'workspace',
}) => {
    const { user } = useAuth();

    const variants = {
        workspaceOnboarding: {
            title: "Workspaces"
        },
    }

    return (
        <div className="pb-4 border-b border-[#282C33]">
            <div>
                <div className="flex justify-between">
                    <div className="flex items-center bg-background mb-4 gap-3">
                        <p className="text-2xl text-[#FFFFFF] font-bold">
                            {variants[variant]?.title}
                        </p>
                    </div>
                </div>
                <div className="flex bg-background w-[480px]">
                    <p className="text-[#B0B0B0]">
                        {variants[variant]?.description}
                    </p>
                </div>
            </div>
            {children}
        </div>
    );
}

export default Headings;