import React, { useState } from 'react';
import './ReqResBody.css';
import CopyIcon from '../../icons/copy-icon.svg';
import 'react-json-pretty/themes/monikai.css';
import { copyToClipboard } from '../../utils/common';
import { Alert, Snackbar, Typography } from "@mui/material";
import JSONPrettyComp from '../Details/JSONPretty/JSONPretty';

const ReqResBody = ({ title, string, status }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setSnackbarOpen(false);
      };

    // Initialize parsedString with proper type checking
    const getParsedString = (input) => {
        if (input === null || input === undefined) {
            return "{}";
        }
        if (typeof input === 'string') {
            try {
                // If it's already a JSON string, parse it to ensure valid JSON
                JSON.parse(input);
                return input;
            } catch {
                return JSON.stringify({}, null, 2);
            }
        }
        // If it's an object, stringify it
        return JSON.stringify(input, null, 2);
    };

    const parsedString = getParsedString(string);
    const [text, setText] = useState(parsedString);

    return (
        <div className='req-res-container'>
            <div className="req-res-top">
                <Typography variant='h6'>{title}</Typography>
                <div className="edit-copy">
                    <img
                        onClick={() => copyToClipboard(parsedString, setSnackbarOpen, setAlertSeverity, setAlertMessage)}
                        className='copy-icon'
                        src={CopyIcon}
                        alt="copy icon"
                    />
                </div>
            </div>
            <div className="req-res-bottom">
                {title !== 'Request' && (
                    <div className={`rounded-sm px-2 py-1 self-end justify-self-center ${status < 300 ? "bg-[#31744A]" : "bg-[#633238]"
                        }`}>
                        {status}
                    </div>
                )}
                <div className="req-res-body">
                    <JSONPrettyComp
                        data={text}
                    />
                </div>
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={alertSeverity}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ReqResBody;
