import React, { useEffect, useState } from "react";
import "./IndividualTest.css";
import { useParams } from "react-router";
import {
    addTestCaseAPI,
    fetchAllTC,
    executeIndividualTest,
    deleteTestScript
} from "../../Actions/tcActions";
import { useDispatch, useSelector } from "react-redux";
import { HiPlusSmall } from "react-icons/hi2";
import { IoMdPlay } from "react-icons/io";
import { FiPaperclip } from "react-icons/fi";
import Layout from '../Pagelayout/Layout';
import { getResponseBody } from "../../Actions/tcActions";
import { useAuth } from "../../AuthContext";
import TestSteps from "../Details/TestSteps/TestSteps";
import DateTime from '../Details/DateTime.js/DateTime';
import AddAPIModal from "../AddAPIModal/AddAPIModal";
import SnackbarComp from "../Snackbar/Snackbar";
import { LuTrash } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import EllipsisDropdown from "../TestSuite/EllipsisDropdown";

/*


IndividualTest component that renders the individual test case page.
It uses the useSelector and useDispatch hooks from react-redux to access the state and dispatch actions.
It uses the useEffect hook to fetch the test cases when the component mounts.
It renders the Sidebar component.
The Sidebar component displays the list of assertions for the selected API.
This component renders on the /test/:id route.


*/


const IndividualTest = () => {
    const { id } = useParams(); // Get the id from the URL
    const [modalOpen, setModalOpen] = useState(false);
    const [headers, setHeaders] = useState([["", ""], ["", ""], ["", ""]]);
    const [apiDetails, setApiDetails] = useState({
        name: null,
        method: "get",
        path: null,
        serverName: null,
        body: null,
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, testCases, testScripts } = useSelector((state) => state.testCases); // Get the test cases from the state
    const { loading: apiLoading, apis, responseBody, error } = useSelector((state) => state.apis); // Get the APIs from the state

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");

    const { apikey, user } = useAuth();


    useEffect(() => {
        dispatch(fetchAllTC(id)); // Fetch all test cases for the id
    }, [dispatch]);


    useEffect(() => {
        if (error) {
            // console.log("error", error);
            setAlertSeverity("error");
            setAlertMessage(`Error adding the API: ${error}`);
            setSnackbarOpen(true);
            dispatch({
                type: "clearError",
            });
        }
    }, [error]);


    let tcName = "";
    let tcNumber = 0;
    let tcDate = "";
    let tcTime = 0;
    let tcDuration = 0;
    let tcPass = null;
    let tcDescription = "";


    // Find the test case with the given id
    if (testCases) {
        testCases?.tests?.forEach((test) => {
            if (test?.id === id) {
                tcName = test?.title;
                tcNumber = test?.tcNumber;
                tcDate = test?.date;
                tcTime = test?.time;
                tcDuration = test?.duration;
                tcPass = test?.pass;
                tcDescription = testScripts.script.find(el => el.name === test.title)?.description
            }
        });
    }


    const handleTestAPI = () => {
        let formattedHeaders = {};
        headers.forEach((header) => {
            if (header[0] !== "" && header[1] !== "") {
                formattedHeaders[header[0]] = header[1];
            }
        });
        // console.log(headers, formattedHeaders, body);
        dispatch(
            getResponseBody({
                body: JSON.parse(apiDetails.body),
                headers: formattedHeaders,
                apiEndpoint: apiDetails.path,
                apiMethod: "GET",
                serverName: apiDetails.serverName,
            })
        );
    };

    let data = []; // Create an array to store the apis

    if (apis !== undefined && apis?.data !== undefined) {
        apis?.data?.forEach((api) => {
            data.push({
                _id: api?.api?._id,
                title: api?.api?.title,
                assertions: api?.api?.assertions,
                pass: api?.api?.pass,
                // failed: api?.api?.fails.length,
                step: api?.api?.step,
                method: api?.api?.method,
                path: api?.api?.path,
            });
        });
        // data = data;
    }
    // var res = data.sort((a, b) => b.step-a.step)
    // // console.log(res.reverse());

    //   if (allAssertions?.length > 0) {
    //     // Filter the assertions based on the selected row
    //     assertions = allAssertions.filter(
    //       (assertion) => assertion?._id === data[selectedRow]._id
    //     );
    //   }


    const handleAddTCAPI = async (e) => {
        e.preventDefault();
        try {
            await dispatch(addTestCaseAPI({
                tcName,
                api: {
                    name: apiDetails.name,
                    baseUrl: apiDetails.serverName,
                    endpoint: apiDetails.path,
                    method: apiDetails.method,
                    body: apiDetails.body ?? null,
                    headers: headers ?? null,
                    expectedStatus: 200, 
                    response: responseBody?.data ?? {},
                },
                apikey
            }))
            setAlertSeverity("info");
            setAlertMessage("Please rerun the test suite to see the changes.");
            setSnackbarOpen(true);
        } catch (error) {
            // On error
            setAlertSeverity("error");
            setAlertMessage("Failed to Add Test Case");
            setSnackbarOpen(true);
        }
    };


    const runTest = () => {
        if (testScripts?.script?.some(script => script.name === tcName)) {
            let script = testScripts?.script?.find(script => script.name === tcName);
            dispatch(executeIndividualTest(script, user?._id, apikey))
        }
    }

    const deleteTest = async (tcName) => {
        setSnackbarOpen(true);
        setAlertMessage("Please rerun the test suite to see the changes.");
        setAlertSeverity("info");
        await dispatch(deleteTestScript({ name: tcName, apikey }));
        navigate("/test-suite");
      }

    const dropdownConfig = [
        { title: "Run", action: runTest, icon: () => <IoMdPlay />, props: [tcNumber, tcName] },
        { title: "Delete", action: deleteTest, icon: () => <LuTrash /> }
      ]

    return (
        <Layout loading={loading || apiLoading}>
            <div className="col-span-9 text-white h-screen overflow-y-scroll no-scrollbar flex w-full">
                <div className="w-[20%] py-4 border-r border-[#2C2E33]">
                    <TestSteps title="Test Case Details" subtitle="Test Steps" data={data} type={{ site: "tc" }} />
                </div>
                <div className="w-[80%] px-16 py-12">
                    <div className="flex items-center justify-between">
                        <div className="bg-[#1D1E20] justify-self-start px-6 py-0.5 rounded border border-[#282C33]">TC{tcNumber.toString().padStart(3, '0')}</div>
                        <div className="flex items-center gap-3 text-[#B0B0B0] relative">
                            <div className="flex gap-2 items-center rounded-md px-2 py-1 border border-[#2C2E33] cursor-pointer" onClick={() => setModalOpen(true)}>
                                <HiPlusSmall /> Add API
                            </div>
                            <div className="rounded-md px-3 py-1 border border-[#2C2E33]">Share</div>
                            <div onClick={runTest} className="rounded-md px-2 py-1 border bg-[#D85C93] border-[#E27AAB] text-white cursor-pointer flex items-center gap-2 text-white">
                                <IoMdPlay />
                                Run Test Case
                            </div>
                            <EllipsisDropdown config={dropdownConfig}/>
                        </div>
                    </div>
                    <div className="my-12 text-[#A0A0A0]">
                        <div className="text-xl mb-6">Test Case - </div>
                        {tcDescription}
                    </div>
                    <div className="flex gap-3 text-[#A0A0A0] pb-16 mb-16 border-b border-[#282C33]">
                        <DateTime date={tcDate} time={tcTime} duration={tcDuration} />
                        <div className={"px-2 py-1 rounded flex gap-2 items-center " + (tcPass ? "bg-[#112313]" : "bg-[#240F10]")}>
                            <div className={"w-[10px] h-[10px] rounded-full " + (tcPass ? "bg-[#65DC8D]" : "bg-[#F87171]")} />
                            <span className={tcPass ? "text-[#DFF9E7]" : "text-[#FECACA]"}>{tcPass ? "Passed" : "Failed"}</span>
                        </div>
                    </div>
                    <div className="relative">
                        <textarea className="rounded-md border border-[#282C33] bg-inherit p-4 min-h-[25vh] w-full focus:outline-none active:outline-none" placeholder="Leave a comment..." />
                        <FiPaperclip className="absolute bottom-6 right-6 cursor-pointer" size={16} />
                    </div>
                    <div className="rounded-md border border-[#282C33] px-2.5 py-0.5 mt-3 cursor-pointer w-max">Save</div>
                </div>
            </div>
            {/* </div> */}
            <AddAPIModal modalOpen={modalOpen} width="85vw" setModalOpen={setModalOpen} apiDetails={apiDetails} responseBody={responseBody} setApiDetails={setApiDetails} headers={headers} setHeaders={setHeaders} handleAddAPI={handleAddTCAPI} handleTestAPI={handleTestAPI} />
            <SnackbarComp open={snackbarOpen} severity={alertSeverity} message={alertMessage} handleClose={() => setSnackbarOpen(false)} />
        </Layout >
    );
};


export default IndividualTest;