import Logo from '../../icons/devzery-logo-icon.svg'
import { useTranslation } from 'react-i18next'

const OnboardingNav = ({active}) => {
    const {t} = useTranslation();
    return <div className="flex justify-between py-8 px-16 w-full items-center">
    <div className=""><img src={Logo} alt="" /></div>
    <div className="flex justify-end items-center text-paragraph">
        <div className="flex items-center mr-8"><span className={"mr-2 rounded-full text-xs w-[18px] h-[18px] block flex items-center justify-center " + (active > 1 ? "bg-gradient-to-br from-[#358751] to-[#70DF95]" : active === 1 ? "bg-gradient-to-b from-gradient-primary-from to-gradient-primary-to" : "bg-dashboard-border-600 text-dashboard-text-white")}>1</span>{t('pageStatus.integrate_sdk')}</div>
        <div className="flex items-center mr-8"><span className={"mr-2 rounded-full text-xs w-[18px] h-[18px] block flex items-center justify-center " + (active > 2 ? "bg-gradient-to-br from-[#358751] to-[#70DF95]" : active === 2 ? "bg-gradient-to-b from-gradient-primary-from to-gradient-primary-to" : "bg-dashboard-border-600 text-dashboard-text-white")}>2</span>{t('pageStatus.collectAPIs')}</div>
        <div className="flex items-center mr-8"><span className={"mr-2 rounded-full text-xs w-[18px] h-[18px] block flex items-center justify-center " + (active > 3 ? "bg-gradient-to-br from-[#358751] to-[#70DF95]" : active === 3 ? "bg-gradient-to-b from-gradient-primary-from to-gradient-primary-to" : "bg-dashboard-border-600 text-dashboard-text-white")}>3</span>{t('pageStatus.verifyAPIs')}</div>
        <div className="flex items-center"><span className={"mr-2 rounded-full text-xs w-[18px] h-[18px] block flex items-center justify-center " + (active > 4 ? "bg-gradient-to-br from-[#358751] to-[#70DF95]" : active === 4 ? "bg-gradient-to-b from-gradient-primary-from to-gradient-primary-to" : "bg-dashboard-border-600 text-dashboard-text-white")}>4</span>{t('pageStatus.swagger_doc')}</div>
    </div>
</div>
}

export default OnboardingNav