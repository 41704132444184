import React, { useEffect, useState } from "react";
import "./APIOverview.css";
// import APITable from "../APITable/APITable";
import { useSelector, useDispatch } from "react-redux";
import { getAllAPIs } from "../../Actions/tcActions";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import Navbar from "../Navbar/Navbar";
// import BaseUrlModal from "../TestSuite/BaseUrlModal/BaseUrlModal";
// import RightSidebar from '../RightSidebar/RightSidebar';

/*

APIOverview component that renders the API overview page of the application.
It uses the useSelector and useDispatch hooks from react-redux to access the state and dispatch actions.
It uses the useEffect hook to fetch the APIs when the component mounts.
It has a table that displays the list of APIs.


*/
const APIOverview = () => {
  const dispatch = useDispatch();
  const { loading, allApis } = useSelector((state) => state.apis); // Load apis from the state

  // Create a list of APIs and pass it to the APITable component
  let apisList = [];
  if (allApis) {
    allApis?.apiNames?.forEach((api) => {
      apisList.push({ api });
    });
  }
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getAllAPIs());
  }, []);

  return (
    <div className="w-full grid grid-cols-10 gap-8 overflow-hidden no-scrollbar">
      <div className="col-span-2">
        {/* <Navbar /> */}
      </div>
      <Backdrop
        sx={{ color: "#E45799", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="col-span-6 py-8 flex flex-col justify-start h-screen overflow-y-scroll no-scrollbar">
        <div className="flex justify-between">
          <select name="workspace-select" className="bg-[#21222D] rounded-sm px-3 py-2 text-white mr-3" id="">
            <option selected disabled>
              Workspace
            </option>
          </select>
          <button className="bg-[#21222D] rounded-sm px-6 py-2 text-white mr-3" onClick={() => setModalOpen(!modalOpen)}>
              View Base URLs
            </button>
          {/* <BaseUrlModal modalOpen={modalOpen} setModalOpen={setModalOpen} /> */}
        </div>
        <div className="grid grid-cols-3 w-[90%] my-12 self-end">
          <select name="repo-select" className="bg-[#21222D] rounded-sm px-3 py-2 text-white mr-3" id="">
            <option selected disabled>
              Repository
            </option>
          </select>
          <select name="branch-select" className="bg-[#21222D] rounded-sm px-3 py-2 text-white mr-3" id="">
            <option selected disabled>
              Branch
            </option>
          </select>
          <select name="commit-select" className="bg-[#21222D] rounded-sm px-3 py-2 text-white mr-3" id="">
            <option selected disabled>
              Commit
            </option>
          </select>
        </div>
        <div className="api-overview-middle-bottom">
          {/* <APITable apis={apisList} /> */}
        </div>
      </div>
      {/* <div className="col-span-2">
        <RightSidebar />
      </div> */}
    </div>
  );
};

export default APIOverview;
