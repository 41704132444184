import { useSelector } from "react-redux";
import { useMemo } from 'react';
import Coverage from '../CoverageComponent/CoverageComponent';

// Utility function to calculate percentage safely
/* const calculatePercentage = (part, total) => {
    if (total <= 0) return 0;

    const percentage = (part / total) * 100;
    return Number(percentage.toFixed(2)); // Round to 2 decimal places
};

// Generic coverage data generation
const generateCoverageData = (dataPoints, total) => {
    return dataPoints.map(point => ({
        ...point,
        value: calculatePercentage(point.value, total)
    }));
}; */

// Test Coverage Component
const TestCoverage = ({ test }) => {
    const testCounts = {
        pass: test?.totalPass ?? 0,
        fail: test?.totalFail ?? 0,
        skip: test?.totalSkip ?? 0,
    };

    const total = Object.values(testCounts).reduce((sum, count) => sum + count, 0);

    const testCoverageData = useMemo(() => {
        const baseData = [
            { label: 'Passed', value: testCounts.pass, color: '#65DC8D', cutout: "95%" },
            { label: 'Failed', value: testCounts.fail, color: '#F87171', cutout: "95%" },
            { label: 'Skipped', value: testCounts.skip, color: '#A0A0A0', cutout: "95%" }
        ];
        /* const coverageData = generateCoverageData(baseData, total); */
        const totalData = { label: 'Total Tests', value: total, color: '#E27AAB' };
        return {
            baseData, //coverageData,
            totalData
        };
    }, [testCounts, total]);

    const fetchTestData = () => {
        // console.log("Fetching test data...", test);
        // Implement actual data fetching logic
    };

    return (
        <Coverage
            title="Test Coverage"
            data={testCoverageData.baseData}
            totalData={testCoverageData.totalData}
            onRefresh={fetchTestData}
            emptyState={total === 0}
            /* tooltipBackgroundColors={{
                'Passed': '#65DC8D',
                'Failed': '#F87171',
                'Skipped': '#A0A0A0',
            }}
            tooltipTitleColors={{
                'Passed': '#fff',
                'Failed': '#fff',
                'Skipped': '#fff',
            }} */
        />
    );
};

// HTTP Methods Coverage Component
const HttpMethodsCoverage = ({ http }) => {
    const methodCounts = {
        get: http?.getCount ?? 0,
        post: http?.postCount ?? 0,
        put: http?.putCount ?? 0,
        patch: http?.patchCount ?? 0,
        del: http?.deleteCount ?? 0,
        others: http?.othersCount ?? 0
    };

    const total = Object.values(methodCounts).reduce((sum, count) => sum + count, 0);

    const httpMethodsCoverageData = useMemo(() => {
        const baseData = [
            { label: 'Get', value: methodCounts.get, color: '#65DC8D', cutout: "95%" },
            { label: 'Post', value: methodCounts.post, color: '#FCD34D', cutout: "95%" },
            { label: 'Put', value: methodCounts.put, color: '#44A5FF', cutout: "95%" },
            { label: 'Patch', value: methodCounts.patch, color: '#9D7CFF', cutout: "95%" },
            { label: 'Delete', value: methodCounts.del, color: '#F87171', cutout: "95%" },
            { label: 'Others', value: 0, color: '#A0A0A0' }
        ];
        /* const coverageData = generateCoverageData(baseData, total); */
        const totalData = { label: 'Total Methods', value: total, color: '#E27AAB' };
        return {
            baseData,
            totalData
        };
    }, [methodCounts, total]);

    const fetchHttpMethodsData = () => {
        // console.log("Fetching http data...", http);
        // Implement actual data fetching logic
    };

    return (
        <Coverage
            title="HTTP Coverage"
            data={httpMethodsCoverageData.baseData}
            totalData={httpMethodsCoverageData.totalData}
            onRefresh={fetchHttpMethodsData}
            emptyState={total === 0}
            /* tooltipBackgroundColors={{
                'Get': '#65DC8D',
                'Post': '#FCD34D',
                'Put': '#44A5FF',
                'Patch': '#9D7CFF',
                'Delete': '#F87171',
            }}
            tooltipTitleColors={{
                'Get': '#fff',
                'Post': '#fff',
                'Put': '#fff',
                'Patch': '#fff',
                'Delete': '#fff',
            }} */
        />
    );
};

// Bug Coverage Component
const BugCoverage = ({ test }) => {
    const bugCounts = {
        open: test?.totalFail ?? 0,
        close: test?.totalClose ?? 0,
    };

    const total = Object.values(bugCounts).reduce((sum, count) => sum + count, 0);

    const bugCoverageData = useMemo(() => {
        const baseData = [
            { label: 'Open', value: bugCounts.open, color: '#E27AAB', cutout: "95%"  },
            { label: 'Closed', value: bugCounts.close, color: '#65DC8D', cutout: "95%" },
        ];
        const totalData = { label: 'Total Bugs', value: total, color: '#E27AAB' };
        return {
            baseData,
            totalData
        };
    }, [bugCounts, total]);

    const fetchTestData = () => {
        // console.log("Fetching test data...", test);
        // Implement actual data fetching logic
    };

    return (
        <Coverage
            title="Bug Coverage"
            data={bugCoverageData.baseData}
            totalData={bugCoverageData.totalData}
            onRefresh={fetchTestData}
            emptyState={total === 0}
            /* tooltipBackgroundColors={{
                'Open': '#E27AAB',
                'Closed': '#65DC8D',
            }}
            tooltipTitleColors={{
                'Open': '#fff',
                'Closed': '#fff',
            }} */
        />
    );
};

// Priority Coverage Component
const PriorityCoverage = ({ test }) => {
    const priorityCounts = {
        high: test?.totalHigh ?? 0,
        medium: test?.totalMedium ?? test?.totalFail ?? 0,
        low: test?.totalLow ?? 0,
    };

    const total = Object.values(priorityCounts).reduce((sum, count) => sum + count, 0);

    const priorityCoverageData = useMemo(() => {
        const baseData = [
            { label: 'High', value: priorityCounts.high, color: '#F87171', cutout: "95%" },
            { label: 'Medium', value: priorityCounts.medium, color: '#FCD34D', cutout: "95%" },
            { label: 'Low', value: priorityCounts.low, color: '#44A5FF', cutout: "95%" },
        ];
        const totalData = { label: 'Total Bugs', value: total, color: '#E27AAB' };
        return {
            baseData,
            totalData
        };
    }, [priorityCounts, total]);

    const fetchTestData = () => {
        // console.log("Fetching test data...");
        // Implement actual data fetching logic
    };

    return (
        <Coverage
            title="Priority Coverage"
            data={priorityCoverageData.baseData}
            totalData={priorityCoverageData.totalData}
            onRefresh={fetchTestData}
            emptyState={total === 0}
            /* tooltipBackgroundColors={{
                'High': '#F87171',
                'Medium': '#FCD34D',
                'Low': '#44A5FF',
            }}
            tooltipTitleColors={{
                'High': '#fff',
                'Medium': '#fff',
                'Low': '#fff',
            }} */
        />
    );
};

// Coverage Sidebar Component
const CoverageSidebar = ({ coverageTypes }) => {
    const { testCases } = useSelector((state) => state.testCases);
    const { methodCount } = useSelector((state) => state.apis);

    const assertions = testCases?.tests?.length > 0 ? {
        totalPass: testCases.totalPass,
        totalFail: testCases.totalFail,
    } : {};

    const coverageComponentMap = {
        "test": <TestCoverage test={assertions} />,
        "http": <HttpMethodsCoverage http={methodCount} />,
        "bug": <BugCoverage test={assertions} />,
        "priority": <PriorityCoverage test={assertions} />
    };

    // Render multiple components based on the array of coverage types
    const renderCoverageComponents = useMemo(() => {
        return coverageTypes.map((type) => {
            const Component = coverageComponentMap[type];

            // If no matching component is found, return null
            if (!Component) {
                console.warn(`No coverage component found for type: ${type}`);
                return null;
            }

            return Component;
        }).filter(Boolean); // Remove any null components
    }, [coverageTypes, assertions, methodCount]);

    return (
        <div className="overflow-auto px-2 py-4 mb-4 h-full no-scrollbar flex flex-col gap-3">
            {renderCoverageComponents}
        </div>
    );
};

export default CoverageSidebar;