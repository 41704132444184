import { memo } from 'react';

const InfoBadge = memo(({ icon: Icon, value }) => {
    // Handle NaN and undefined values
    return (
        <div className="inline-flex items-center space-x-2 px-2 py-1 rounded border border-dashboard-dark-400">
            <Icon className="h-5 w-5" />
            <span>{value}</span>
        </div>
    );
});

export default InfoBadge;