import { useState } from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { getMethodClasses } from "../../../../utils/ui";


const APIItem = ({api, type}) => {
    const [selected, setSelected] = useState(api._id == type?.val ? true : false);
    const navigate = useNavigate();

    return <div>
        <div className={"flex items-center text-[#A0A0A0] justify-between p-3 cursor-pointer hover:bg-[#141516] " + ((type?.val === api._id) ? "bg-[#141516]" : "")} onClick={type?.site === "tc" ? () => navigate(`/api/${api._id}`) : () => setSelected(!selected)}>
            <div className="flex items-center gap-3 cursor-pointer group">
                <MdOutlineArrowBackIos className={"#B0B0B0 cursor-pointer " + (selected ? "-rotate-90" : "rotate-180")} />
                <span className={"rounded-full px-2 py-1 text-xs capitalize " + getMethodClasses(api.method)}>{api.method}</span>
                <div className="text-nowrap w-[120px] group-hover:w-[155px] overflow-x-scroll no-scrollbar">{api.title}</div>
            </div>
            <div className="group-hover:hidden">{(api?.assertions?.status?.pass && api?.assertions?.response?.pass) ? <IoCheckmarkCircleOutline className="text-[#65DC8D]" size={18} /> : <IoMdCloseCircleOutline className="text-[#F87171]" size={18} />}</div>
        </div>
        {(type?.site === "api" && selected)? <div className="border-l border-[#2C2E33] ml-6">
            <div className="py-3 cursor-pointer hover:bg-[#141516] pl-6 cursor-pointer" onClick={() => navigate(`/api/${api._id}`)}>
                <div className={"mb-3 rounded-full flex gap-3 items-center bg-[#1D1E20] px-1.5 w-max " + (api.assertions?.status?.pass ? "text-[#65DC8D]" : "text-[#F87171]")}>
                    <div className={"w-[10px] h-[10px] rounded-full " + (api.assertions?.status?.pass ? "bg-[#65DC8D]" : "bg-[#F87171]")} />
                    1
                </div>
                Status
            </div>
            <div className="py-3 cursor-pointer hover:bg-[#141516] pl-6 cursor-pointer" onClick={() => navigate(`/api/${api._id}`)}>
                <div className={"mb-3 rounded-full flex gap-3 items-center bg-[#1D1E20] px-1.5 w-max " + (api.assertions?.response?.pass ? "text-[#65DC8D]" : "text-[#F87171]")}>
                    <div className={"w-[10px] h-[10px] rounded-full " + (api.assertions?.response?.pass ? "bg-[#65DC8D]" : "bg-[#F87171]")} />
                    2
                </div>
                Response
            </div>
        </div> : null}
    </div>
}

export default APIItem;