import { memo, useState } from 'react';
import { NumberIcon } from "../../icons";
import { Link, useNavigate } from 'react-router-dom';
import { getStatusStyles } from '../../utils/ui';
import { MdOutlineDisabledVisible } from "react-icons/md";
import { LuTrash } from "react-icons/lu";
import { updateBugs, deleteBugs } from '../../Actions/tcActions';
import { useDispatch } from 'react-redux';
import DateTime from '../Details/DateTime.js/DateTime';
import EllipsisDropdown from '../TestSuite/EllipsisDropdown';

const StatusBadge = memo(({ status, ignore }) => {
  const styles = getStatusStyles(status, ignore);

  return (
    <span className={`px-2 py-1 rounded text-sm ${styles.container}`}>
      <span className={`inline-block w-2.5 h-2.5 rounded-full mr-2 ${styles.dot}`}></span>
      {status}
    </span>
  );
});

const TestCard = ({
  index,
  bug
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  // Convert boolean status to string
  // const status = typeof rawStatus === 'boolean'
  //   ? (rawStatus ? "" : "Open")
  //   : rawStatus;

  const handleCardClick = () => {
    navigate(`/bug/${bug._id}`);
  };

  const handleIgnoreClick = async () => {
    await dispatch(updateBugs(bug._id, { ignore: !bug.ignore }));
  }

  const handleBugDelete = async () => {
    await dispatch(deleteBugs(bug._id));
  }

  const dropdownConfig = [
    { title: bug?.ignore ? "Enable" : "Disable", action: handleIgnoreClick, icon: () => <MdOutlineDisabledVisible /> },
    { title: "Delete", action: handleBugDelete, icon: () => <LuTrash /> }
  ]

  return (
    <div key={index} className="mb-3 bg-dashboard-dark-700 rounded-md px-3.5 py-3 border border-dashboard-dark-500 cursor-pointer relative"
    // onClick={handleCardClick}
    >
      <div className='flex flex-col gap-2' onClick={handleCardClick}>
        <div className="flex justify-between">
          <div className="flex items-center space-x-2">
            <div className="inline-flex items-center px-2 py-1 rounded border border-dashboard-dark-500 bg-dashboard-dark-600 text-sm text-dashboard-text-white">
              <NumberIcon className="h-4 w-4" />
              <span>{index}</span>
            </div>
            <StatusBadge status={bug?.status} ignore={bug?.ignore} />
          </div>
        </div>
        <p className="text-dashboard-text-white">{bug?.title}</p>
        <DateTime date={bug?.date} time={bug?.time} duration={bug?.duration} priority={bug?.priority} />
      </div>
      <div className="absolute top-4 right-4">
        <EllipsisDropdown config={dropdownConfig} />
      </div>
    </div>
  );
};

// Only re-render if props change
export default memo(TestCard);