import { PiLink } from "react-icons/pi";

const SDKCard = ({image, lang, docsUrl, githubUrl}) => {
    return <div className="bg-dashboard-dark-100 border border-[#2E3138] p-3 rounded-md grid grid-cols-3">
    <div className="col-span-1 mr-3 p-4"><img className="w-full" src={image} alt="" /></div>
    <div className="col-span-2 flex flex-col justify-between">
        <div className="font-semibold capitalize">{lang} SDK</div>
        <div className="flex mt-2">
            {docsUrl && <a href={docsUrl} target="_blank">
                <div className="flex items-center mr-3 border border-dashboard-border-500 bg-dashboard-dark-600 px-2 py-1 rounded">Docs <PiLink className="ml-2" /></div>
            </a>}
            <a href={githubUrl} target="_blank">
                <div className="flex items-center border border-dashboard-border-500 bg-dashboard-dark-600 px-2 py-1 rounded">Github <PiLink className="ml-2" /></div>
            </a>
        </div>
    </div>
</div>
}

export default SDKCard;