// Add a request interceptor to check for the access token before making a request to the server
/*

The axios.interceptors.request.use() method is used to intercept the request before it is sent to the server.
The method takes two arguments, the first argument is a function that is executed before the request is sent to the server.
The second argument is a function that is executed when there is an error in the request.
The function takes the config object as an argument, which contains the request configuration.
The function returns the config object after adding the Authorization header to it.
The Authorization header contains the access token.
The access token is retrieved from the local storage.
The access token is decoded using the jwtDecode() method.
The decoded token contains the expiration time of the token.
The current time is calculated using the Date.now() method.
If the expiration time of the token is less than the current time, the access token is refreshed.
The refreshAccessToken() method is called to refresh the access token.
The access token is added to the Authorization header.
The config object is returned.

*/

import axios from 'axios';

let dburl1 = process.env.REACT_APP_DATABASE_URL || "http://localhost:3000";

const axiosInstance = axios.create({
  withCredentials: true // This ensures cookies are always sent with requests
});

// Flag to prevent multiple refresh attempts
let isRefreshing = false;
// Queue of requests to retry after token refresh
let refreshSubscribers = [];

// Function to add callbacks to the queue
const addSubscriber = (callback) => {
  refreshSubscribers.push(callback);
}

// Function to execute all callbacks in the queue
const onRefreshed = (token) => {
  refreshSubscribers.forEach(callback => callback(token));
  refreshSubscribers = [];
}

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Ignore refresh token requests to prevent loops
    if (originalRequest.url === `${dburl1}/auth/refresh-token`) {
      return Promise.reject(error);
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        // If refreshing is in progress, add request to queue
        return new Promise((resolve) => {
          addSubscriber((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            resolve(axiosInstance(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const response = await axiosInstance.post(`${dburl1}/auth/refresh-token`, {}, { withCredentials: true });
        const newToken = response.data.token; // Adjust based on your API response
        
        isRefreshing = false;
        onRefreshed(newToken);
        
        // Update the authorization header
        originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
        // console.log('Access token refreshed. Retrying original request.');
        // console.log(originalRequest);
        return axiosInstance(originalRequest);

      } catch (refreshError) {
        isRefreshing = false;
        // Handle logout here instead of redirecting
        // You might want to call a logout function or dispatch a logout action
        // console.log('Refresh token failed. User needs to login again.');
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;