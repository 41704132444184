import { useEffect } from 'react';
import { Panel, PanelGroup } from "react-resizable-panels";
import Heading from './components/Headings';
import WorkspaceCard from './components/WorkspaceCards';
import { useAuth } from '../../AuthContext';

const InviteOnboarding = () => {

    const { workspaces, getworkspaces } = useAuth();

    useEffect(() => {
        getworkspaces();
    },[]);

    return (
        <div className="flex flex-col min-h-0 mt-16 ml-[97px] mr-[97px]">
            <PanelGroup direction="vertical" className="justify-centre gap-4">
                <Panel defaultSize={20} minSize={15}>
                    <Heading variant='workspaceOnboarding' />
                </Panel>
                <Panel defaultSize={80} minSize={15}>
                    <div className="flex flex-row gap-[12px] mt-4 min-w-[100%]">
                        {workspaces.map((workspace) => (
                            <WorkspaceCard key={workspace?._id} workspace={workspace} />
                        ))}
                    </div>
                </Panel>
            </PanelGroup>
        </div>
    );
};

export default InviteOnboarding;