import { Snackbar, Alert } from "@mui/material"

const SnackbarComp = ({ open, handleClose, severity, message }) => {
    return <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
    >
        <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
        >
            {message}
        </Alert>
    </Snackbar>
}

export default SnackbarComp