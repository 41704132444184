import { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { IoMdPlay } from "react-icons/io";
import { LuTrash } from "react-icons/lu";
import DateTime from '../Details/DateTime.js/DateTime';
import EllipsisDropdown from './EllipsisDropdown';


const getStatusStyles = (status) => {
  const styles = {
    Passed: {
      container: "bg-dashboard-dark-201 text-dashboard-text-green",
      dot: "bg-dashboard-dark-203"
    },
    Failed: {
      container: "bg-dashboard-dark-200 text-dashboard-text-red",
      dot: "bg-dashboard-dark-202"
    },
    default: {
      container: "bg-dashboard-dark-600 text-dashboard-text-gray",
      dot: "bg-dashboard-text-gray"
    }
  };


  return styles[status] || styles.default;
};


const StatusBadge = memo(({ status }) => {
  const styles = getStatusStyles(status);


  return (
    <span className={`px-2 py-1.5 rounded-md text-sm ${styles.container}`}>
      <span className={`inline-block w-2.5 h-2.5 rounded-full mr-2 ${styles.dot}`}></span>
      {status}
    </span>
  );
});


const TestCard = ({
  index,
  test,
  onRerun,
  selectedTests,
  setSelectedTests,
  deleteTest,
  selectAll
}) => {
  const [selected, setSelected] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectAll) setSelected(true);
    else setSelected(false);
  }, [selectAll])


  const {
    _id,
    tcNumber,
    pass: rawStatus,
    name,
    date,
    time,
    duration,
    description,
    priority = "Medium" // Default priority
  } = test || {};


  // Convert boolean status to string
  const status = typeof rawStatus === 'boolean'
    ? (rawStatus ? "Passed" : "Failed")
    : rawStatus;


  // console.log("Status", status);


  const handleCardClick = (e) => {
    // Prevent navigation if clicking on the rerun button
    if (e.target.closest('.rerun-button')) {
      return;
    }


    if (_id) {
      navigate(`/test/${_id}`);
    }
  };


  const handleTestSelect = () => {
    if (selectedTests.includes(name)) {
      setSelectedTests(selectedTests.filter((test) => test !== name));
    } else {
      setSelectedTests([...selectedTests, name]);
    }
    setSelected(!selected);
    // console.log(selectedTests);
  }

  const dropdownConfig = [
    { title: "Run", action: onRerun, icon: () => <IoMdPlay />, props: [tcNumber, name] },
    { title: "Delete", action: deleteTest, icon: () => <LuTrash /> }
  ]

  const priorityArr = ['Low', 'Medium', 'High']

  return (
    <div key={index} className="mb-3 bg-dashboard-dark-700 rounded-md p-2 border border-dashboard-dark-500 cursor-pointer flex"
    >
      <div className="mt-2 mr-3"><input
        // style={{ marginRight: "5px" }}
        type="checkbox"
        id="apiSelected"
        name="apiSelected"
        checked={selected}
        onClick={handleTestSelect}
      /></div>
      <div className='w-full' onClick={handleCardClick}>
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <span className="inline-flex items-center px-2 py-1.5 rounded-md border border-dashboard-dark-500 bg-dashboard-dark-600 text-[12px] text-dashboard-text-white">TC{tcNumber.toString().padStart(3, '0')}</span>
            <StatusBadge status={status} />
          </div>
        </div>
        <p className="my-2 text-dashboard-text-white">{description ?? name}</p>
        <DateTime date={date} time={time} duration={duration} priority={priorityArr[priority]} />
      </div>
      <EllipsisDropdown config={dropdownConfig} />
    </div>
  );
};


// Only re-render if props change
export default memo(TestCard);