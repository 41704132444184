import React, { createContext, useContext, useState, useEffect } from 'react';
// import axios from 'axios';
import axiosInstance from "./axiosInterceptor";
import { config } from "./config";

const dburl1 = process.env.REACT_APP_DATABASE_URL || "http://localhost:3000";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [apikey, setApikey] = useState(null);
  const [workspaces, setWorkspaces] = useState([]);
  const [workspaceContext, setWorkspaceContext] = useState(workspaces[0]);

  useEffect(() => {
    checkAuth();
  }, []);

  const updateUser = async (details) => {
    try {
      const { data } = await axiosInstance.patch(`${dburl1}/auth/update-user`, details, { withCredentials: true });
      setUser(data.user);
    } catch (error) {
      setError(error.response ? error.response.data.message : error.message);  
    }
  }

  const updateOrgDetails = async (details) => {
    try {
      const { data } = await axiosInstance.patch(`${dburl1}/auth/org-details`, details, { withCredentials: true });
      setUser(data.user);
    } catch (error) {
      setError(error.response ? error.response.data.message : error.message);  
    }
  }

  const checkAuth = async () => {
    try {
      const response = await axiosInstance.get(`${dburl1}/auth/check-auth`, { withCredentials: true });
      const flag = JSON.parse(localStorage.getItem('flag'));
      setUser(flag ? {...response.data.user, isNew:false} : response.data.user);
      if (user?.workspaces.length > 0) {
        setWorkspaceContext(user?.workspaces[0]);
      }
      setIsAuthenticated(true);
      await getapikey();
    } catch (error) {
      console.error('Authentication check failed:', error);
      setUser(null);
      setIsAuthenticated(false);
      setApikey(null);
    } finally {
      setLoading(false);
    }
  };

  const login = async (email, password, rememberMe) => {
    setLoading(true);
    console.log('Login function called', { email });
    try {
      console.log('Attempting API call to:', `${dburl1}/auth/login`);
      const response = await axiosInstance.post(`${dburl1}/auth/login`, { email, password, rememberMe }, { withCredentials: true });
      console.log('Login API response:', response.data);
      
      if (response.data.success) {
        setUser(response.data.user);
        setIsAuthenticated(true);
        if (response.data.user?.workspaces.length > 0) {
          setWorkspaceContext(response.data.user?.workspaces[0]);
        }
        return true;
      } else {
        console.warn('Login failed');
        return false;
      }
    } catch (error) {
      setError(error.response ? error.response.data.message : error.message);
      const errorMessage =  error.response ? error.response.data : error.message || "Signin Failed failed";
      return Promise.reject(errorMessage);  // Reject with error message for easier handling
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      await axiosInstance.get(`${dburl1}/auth/logout`, {}, { withCredentials: true });
      setUser(null);
      setIsAuthenticated(false);
      setApikey(null);
      window.location.reload();
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      setUser(null);
      setIsAuthenticated(false);
      setApikey(null);
    }
  };

  const googleSSO = async (token) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        `${dburl1}/auth/google-sso`,
        { token },
        { withCredentials: true }
      );
      setUser(response.data.user);
      setIsAuthenticated(true);
      await getapikey();
      return response.data;
    } catch (error) {
      console.error("Google SSO failed:", error);
      const errorMessage = error.response?.data?.message || "Google SSO failed";
      setError(errorMessage);
      return Promise.reject(errorMessage);  // Reject with error message for easier handling
    } finally {
      setLoading(false);
    }
  };

  const getapikey = async () => {
    try {
      const response = await axiosInstance.get(`${dburl1}/api/get-key`,{ withCredentials: true })
      setApikey(response.data.apiKey?.key);
    } catch (error) {
      console.error('API key fetch failed:', error);
      throw error.response.data.message;
    }
  }

  const getnewapikey = async () => {
    try {
      const response = await axiosInstance.get(`${dburl1}/auth/regenerate-key`)
      setApikey(response.data.apiKey?.key);
    } catch (error) {
      console.error('API key fetch failed:', error);
      throw error.response.data.message;
    }
  }

  const githubSSO = async (code) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${dburl1}/auth/github-sso?code=${code}`,
        { withCredentials: true }
      );
      setUser(response.data.user);
      setIsAuthenticated(true);
      await getapikey();
      return response.data;
    } catch (error) {
      console.error("Github SSO failed:", error);
      const errorMessage = error.response?.data?.message || "Github Login failed";
      setError(errorMessage);
      // return Promise.reject(errorMessage);  // Reject with error message for easier handling
    } finally {
      setLoading(false);
    }
  };
  
  const getworkspaces = async () => {
    try {
    const response = await axiosInstance.get(
      `${dburl1}/workspaces`,
      { withCredentials: true }
    );
    setWorkspaces(response.data?.workspaces);
    setWorkspaceContext(response.data?.workspaces[0]);
  } catch (error) {
    console.error("Workspace fetch failed:", error);
    const errorMessage = error.response?.data?.message || "Workspace fetch failed";
    setError(errorMessage);
    // return Promise.reject(errorMessage);  // Reject with error message for easier handling
  }
  }

  const setWorkspacesContext = async (workspace) => {
    try {
      console.log('Setting workspace context:', workspace);
      const response = await axiosInstance.post(
        `${dburl1}/workspaces/switchWorkspace`,
        { workspaceId: workspace?._id },
        { withCredentials: true }
      );
      console.log('Workspace context set:', response.data.workspace);
      setWorkspaceContext(response.data.workspace);
    } catch {
      console.error("Workspace context set failed:", error);
      const errorMessage = error?.response?.data?.message || "Workspace context set failed";
      setError(errorMessage);
      // return Promise.reject(errorMessage);  // Reject with error message for easier handling
    }
  }


  const validateJoiningToken = async (token) => {
    try{
        const response = await axiosInstance.post(`${dburl1}/workspaces/join/${token}`, {
            withCredentials: true
        });

        return response.data;
    } catch {
        console.error("Error validating joining token");
    }
}

  const value = {
    user,
    loading,
    apikey,
    isAuthenticated,
    error,
    workspaces,
    workspaceContext,
    login,
    getapikey,
    logout,
    checkAuth,
    googleSSO,
    updateUser,
    updateOrgDetails,
    githubSSO,
    getnewapikey,
    validateJoiningToken,
    getworkspaces,
    setWorkspacesContext
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}