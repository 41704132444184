import React, { useState, useEffect } from 'react';
import './App.css';
import { SignIn } from './components/SignIn/SignIn';
import { WelcomePage } from './components/WelcomePage/WelcomePage';
import { WaitlistedPage } from './components/Waitlisted/WaitlistedPage';
import { ResetPassword } from './components/ResetPassword/ResetPassword';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google'
import BugTracker from './components/BugTracker/BugTracker';
import IndividualBug from './components/IndividualBug/IndividualBug';
import APIOverview from './components/APIOverview/APIOverview';
import Integrations from './components/Integrations/Integrations';
import ChatBot from './components/ChatBot/ChatBot';
import OrgDetails from './components/orgDetails/orgDetails';
import IntegrateSDK from './components/IntegrateSDK/IntegrateSDK';
import NotFound from './components/NotFound/NotFound';
import { AuthProvider, useAuth } from './AuthContext'; 
import logo from './components/ChatBot/logo2.png';
import Hoppscotch from './components/Hoppscotch/Hoppscotch';
import './i18n.js';
import TestSuite from './components/TestSuite/TestSuite.jsx';
import Settings from './screens/settings/Settings';
import IndividualAssertion from './components/IndividualAssertion/IndividualAssertion.jsx';
import BaseURL from './components/BaseURL/BaseURL.jsx'
import AddTC from './components/AddTC/AddTC.jsx';
import IndividualAPI from './components/IndividualAPI/IndividualAPI.jsx'
import IndividualTest from './components/IndividualTest/IndividualTest.jsx';
import {JoinPage} from './screens/JoinPage/JoinPage'
import InviteOnboarding from './screens/InviteOnboarding/InviteOnboarding'
/*

Main App component that renders the entire application.
The App component uses the react-router-dom library to handle routing in the application.
The App component uses the useSelector and useDispatch hooks from react-redux to access the state and dispatch actions.
The App component uses the useEffect hook to check if the user is already logged in.
The App component renders different components based on the user's authentication status.

*/

function WaitlistCheck({ children }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user?.waitlisted && location.pathname !== '/waitlisted') {
      navigate('/waitlisted', { replace: true });
    }
  }, [user, navigate, location]);

  if (user?.waitlisted && location.pathname !== '/waitlisted') {
    return <WaitlistedPage />;
  }

  return children;
}

function ProtectedRoute({ children }) {
  const { user, loading } = useAuth();

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

function App() {
  const { checkAuth, user } = useAuth();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 1024);  // Set your threshold here
    };

    // Add event listener for screen resize
    window.addEventListener('resize', checkScreenSize);

    // Check screen size initially
    checkScreenSize();

    // Clean up the event listener on unmount
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    checkAuth();
  }, []);

  if (isSmallScreen) {
    return (
      <div className="show-unsupported" >
        <img src= {logo} 
        alt="Logo"
        style={{ width: '100px', marginBottom: '20px' }}
        />
        <div className='unsupported-text'>
      Please Use Desktop or Laptop
      </div>
    </div>
    );
  }

  

  // console.log('user', user);

  return (
    <div className="App">
      <GoogleOAuthProvider clientId="817077646276-uak1j9jq5ud8cu4qnl892kse1p80pnvg.apps.googleusercontent.com">
        <Router>
          <Routes>
            <Route path="/" element={user && !user.isNew ? <Navigate to="/test-suite" /> : <SignIn />} />
            <Route path="/login" element={user && !user.isNew ? <Navigate to="/test-suite" /> : <SignIn />} />
            <Route path="/org-details" element={
              <ProtectedRoute>
                {user?.isNew ? <OrgDetails /> : <Navigate to="/test-suite" />}
              </ProtectedRoute>
            } />
            <Route path='/invieOnboarding' element={<InviteOnboarding />} />
            <Route path="/join/:token" element={<JoinPage />} /> {/* Add the new route */}
            <Route path="/*" element={
              <WaitlistCheck>
                <Routes>
                  <Route path="/waitlisted" element={<WaitlistedPage />} />
                  <Route path="/onboarding" element={<WelcomePage />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/integrate-sdk" element={
                    <ProtectedRoute>
                      {user?.isNew && user?.orgDetails ? <IntegrateSDK /> : <Navigate to="/login" />}
                    </ProtectedRoute>
                  } />
                  <Route path="/chat" element={<ChatBot />} />
                  <Route path="/test-suite" element={<ProtectedRoute><TestSuite /></ProtectedRoute>} />                  
                  <Route path="/add-test-case" element={<ProtectedRoute><AddTC /></ProtectedRoute>} />
                  <Route path='/test/:id' element={<ProtectedRoute><IndividualTest /></ProtectedRoute>} />
                  <Route path='/api/:id' element={<ProtectedRoute><IndividualAPI /></ProtectedRoute>} />
                  <Route path='/api/assertion/:id' element={<ProtectedRoute><IndividualAssertion /></ProtectedRoute>} />
                  <Route path='/base-url' element={<ProtectedRoute><BaseURL /></ProtectedRoute>} />
                  <Route path='/bug-tracker' element={<ProtectedRoute><BugTracker /></ProtectedRoute>} />
                  <Route path='/bug/:id' element={<ProtectedRoute><IndividualBug /></ProtectedRoute>} />
                  <Route path='/api-overview' element={<ProtectedRoute><APIOverview /></ProtectedRoute>} />
                  <Route path='/api-test' element={<ProtectedRoute><Hoppscotch /></ProtectedRoute>} />
                  <Route path='/integrations' element={<ProtectedRoute><Integrations /></ProtectedRoute>} />
                  <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </WaitlistCheck>
            } />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </div>
  );
}

function AppWrapper() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

export default AppWrapper;
