import React, { useState } from "react";
import HeaderRow from "../HeaderRow/HeaderRow";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateAPI } from "../../../Actions/tcActions";
import { FiArrowUpRight } from "react-icons/fi";


const Table = ({dependencies, headers, setHeaders, api, ind}) => {
    const [toggleState, setToggleState] = useState(1);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let totalPass = 0;
    let totalFail = 2;
    if (
        api?.assertions?.status.pass
    ) {
        totalPass += 1;
        totalFail -= 1;
    }
    if (
        api?.assertions?.response.pass
    ) {
        totalPass += 1;
        totalFail -= 1;
    }

    const handleHeaderDelete = (ind) => {
        let tempArr = [...headers]
        tempArr.splice(ind, 1);
        let temp = {};
        tempArr.forEach(header => {
            if (header[0] !== "" && header[1] !== "") {
                temp[header[0]] = header[1];
            }
        });
        // console.log({ assertions: { ...individualAPI?.data?.assertions, request_headers: temp } });
        dispatch(updateAPI({ assertions: { ...api?.assertions, request_headers: temp } }, ind));
        setHeaders(tempArr)
    }

    const renderHeaders = () => {
        let headerArr = [];
        headers.map((header, index) => headerArr.push(<HeaderRow header={header} handleHeaderDelete={handleHeaderDelete} ind={index} />));
        return headerArr;
    }

    const renderToggleStateComps = (toggle) => {
        switch (toggle) {
            case 1:
                return <React.Fragment>
                    <div className="grid grid-cols-6 border-b border-[#2C2E33]">
                        <div className="border-r border-[#2C2E33] px-6 py-2">Assertion</div>
                        <div className="col-span-5 px-6 py-2 text-sm"><span className="px-4 bg-[#261322] rounded-full text-[#FF86BE]">02</span></div>
                    </div>
                    <div className="grid grid-cols-6 border-b border-[#2C2E33]">
                        <div className="border-r border-[#2C2E33] px-6 py-2">Passed</div>
                        <div className="col-span-5 px-6 py-2 text-sm"><span className="px-4 bg-[#112313] rounded-full text-[#65DC8D]">0{totalPass}</span></div>
                    </div>
                    <div className="grid grid-cols-6 border-b border-[#2C2E33]">
                        <div className="border-r border-[#2C2E33] px-6 py-2">Failed</div>
                        <div className="col-span-5 px-6 py-2 text-sm"><span className="px-4 rounded-full bg-[#240F10] text-[#F87171]">0{totalFail}</span></div>
                    </div>
                </React.Fragment>
            case 2:
                return <div className="max-h-[25vh] overflow-y-auto no-scrollbar">
                    <div className="grid grid-cols-12 border-b border-[#2C2E33]">
                        <div className="col-span-1" />
                        <div className="col-span-4 px-3 py-2 border-x border-[#2C2E33]">Key</div>
                        <div className="col-span-7 px-3 py-2">Value</div>
                    </div>
                    {renderHeaders()}
                </div>
            case 3:
                return <div className="max-h-[25vh] overflow-y-auto no-scrollbar">
                    <div className="grid grid-cols-10 border-b border-[#2C2E33]">
                        <div className="col-span-3 px-3 py-2 border-r border-[#2C2E33]">API Name</div>
                        <div className="col-span-7 px-3 py-2">End Point</div>
                    </div>
                    {dependencies.map((api, index) => <div className="grid grid-cols-10 border-b border-[#2C2E33]">
                        <div className="col-span-3 px-3 py-2">{api.title}</div>
                        <div className="col-span-6 px-3 py-2 border-x border-[#2C2E33]">{api.path}</div>
                        <div className="col-span-1 px-3 py-2 flex items-center justify-center hover:text-white" onClick={() => navigate(`/api/${api._id}`)}>
                            <span className="rounded-md p-1.5 hover:bg-[#1D1E20] cursor-pointer">
                                <FiArrowUpRight size={16} />
                            </span>
                        </div>
                    </div>)}
                </div>
            default:
                return "{}"
        }
    }

    return <div className="rounded-md border border-[#2C2E33] text-[#A0A0A0]">
        <div className="flex justify-start border-b border-[#2C2E33] bg-[#0F1011]">
            <div className={"px-6 py-2 cursor-pointer" + (toggleState === 1 ? " border-b-2 border-[#E27AAB] text-[#D9D9D9]" : "")} onClick={() => setToggleState(1)}>Stats</div>
            <div className={"px-6 py-2 cursor-pointer" + (toggleState === 2 ? " border-b-2 border-[#E27AAB] text-[#D9D9D9]" : "")} onClick={() => setToggleState(2)}>Headers</div>
            <div className={"px-6 py-2 cursor-pointer" + (toggleState === 3 ? " border-b-2 border-[#E27AAB] text-[#D9D9D9]" : "")} onClick={() => setToggleState(3)}>Dependency APIs</div>
        </div>
        {renderToggleStateComps(toggleState)}
    </div>
}

export default Table;