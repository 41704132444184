import React, { useEffect, useState } from "react";
import "./IndividualAPI.css";
import {
    fetchAllTC,
    fetchIndividualAPI,
} from "../../Actions/tcActions";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Panel, PanelGroup } from 'react-resizable-panels';
import Layout from "../Pagelayout/Layout";
import MethodPath from "../Details/MethodPath.js/MethodPath";
import DateTime from "../Details/DateTime.js/DateTime";
import TestSteps from "../Details/TestSteps/TestSteps";
import ReqRes from "../Details/ReqRes/ReqRes";
import Table from "./Table/Table";

/*




IndividualAPI component that renders the individual API page of the application.
It uses the useSelector and useDispatch hooks from react-redux to access the state and dispatch actions.
It uses the useParams hook from react-router-dom to access the ID parameter.
It uses the useEffect hook to fetch the individual API and assertions when the component mounts.
It renders the ReqResBody component to display the request and response body of the API.
It renders the StatusBtn component to display the status of the API.
It renders the TypeBtn component to display the type of the API.
It renders the APISidebar component to display the sidebar with API details.




*/
const IndividualAPI = () => {
    const dispatch = useDispatch();
    const [dependencies, setDependenies] = useState([])
    const [headers, setHeaders] = useState([]);
    const { id } = useParams();
    const { apis, individualAPI, loading } = useSelector(
        (state) => state.apis
    ); // Load individual API from the state


    const { testCases } = useSelector(
        (state) => state.testCases
    ); // Load individual API from the state


    // Create a list of assertions to display
    let data = [];
    let apiPass = (individualAPI?.data?.assertions?.status?.pass && individualAPI?.data?.assertions?.status?.pass);

    if (apis) {
        apis.data?.forEach(api => {
            data.push(api.api); // Add the API to the list of assertions
        })
    }

    useEffect(() => {
        dispatch(fetchIndividualAPI(id)); // Fetch the individual API corresponding to the ID
    }, [id]);


    let testCase = {}
    if (testCases) {
        testCase = testCases?.tests.filter(test => test.id === individualAPI?.data?.parentSuiteId)
    }
    useEffect(() => {
        if (individualAPI && individualAPI?.data) {
            dispatch(fetchAllTC(individualAPI?.data?.parentSuiteId))

            if (individualAPI?.data?.assertions?.request_headers) {
                Object.entries(individualAPI?.data?.assertions?.request_headers).forEach(([key, value]) => {
                    if (!headers.some(header => header[0] === key)) {
                        headers.push([key, value])
                    }
                });
            }
        }
    }, [individualAPI])


    useEffect(() => {
        if (apis && apis?.data) {
            const newDependencies = [...dependencies]; // Clone the current state to avoid mutation

            apis.data.forEach(api => {

                // Check if the dependency is from a previous step
                if (api?.api?.step < individualAPI?.data?.step) {
                    // Check if the dependency already exists
                    if (!newDependencies.some(dep => dep.title === api?.api?.title)) {
                        newDependencies.push(api.api); // Add new dependency
                    }
                }
            });

            // Only update state if new dependencies were added
            if (newDependencies.length !== dependencies.length) {
                setDependenies(newDependencies); // Update state with the new array
            }
        }
    }, [apis, individualAPI, dependencies]);

    const reqresConfig = [
        {name: "Request", data: individualAPI?.data?.assertions?.body ?? {}},
        {name: "Expected Response", data: individualAPI?.data?.assertions?.response?.expected ?? {}},
        {name: "Actual Response", data: individualAPI?.data?.assertions?.response?.actual ?? {}},
    ]

    if (!individualAPI?.data?.pass && individualAPI?.data?.assertions?.err?.message) {
        reqresConfig.push({name: "Error Details", data: individualAPI?.data?.assertions?.err?.message ?? ""})
    }

    return <Layout loading={loading}>
        {/* <Loader loading={loading} /> */}
        <div className="flex flex-col h-full">
            <PanelGroup direction="horizontal" className="flex-1 overflow-auto">
                <Panel minSize={20} className="overflow-auto pb-8 no-scrollbar border-r border-[#2C2E33]">
                    <TestSteps title="Test Case Details" subtitle="Test Steps" data={data} type={{site: "api", val: id}} />
                </Panel>
                <Panel minSize={80} className="overflow-auto no-scrollbar px-4 py-6">
                    <div className="text-[#A0A0A0] mb-4">{individualAPI?.data?.title}</div>
                    <DateTime date={testCase[0]?.date} time={testCase[0]?.time} duration={testCase[0]?.duration} />
                    <div className="grid grid-cols-8 gap-4 my-4">
                        <MethodPath method={individualAPI?.data?.method} path={individualAPI?.data?.path} extraClasses='col-span-7' />
                        <div className={"px-2.5 py-1 rounded flex gap-2 items-center justify-center col-span-1 " + (apiPass ? "bg-[#112313]" : "bg-[#240F10]")}>
                            <div className={"w-[10px] h-[10px] rounded-full " + (apiPass ? "bg-[#65DC8D]" : "bg-[#F87171]")} />
                            <span className={apiPass ? "text-[#DFF9E7]" : "text-[#FECACA]"}>{apiPass ? "Passed" : "Failed"}</span>
                        </div>
                    </div>
                    <Table dependencies={dependencies} headers={headers} setHeaders={setHeaders} api={individualAPI?.data} ind={id} />
                    <ReqRes config={reqresConfig} duration={individualAPI?.data?.duration} status={individualAPI?.data?.assertions?.status?.actual} />
                </Panel>
            </PanelGroup>
        </div>
    </Layout>
};




export default IndividualAPI;
