import React, { useState } from 'react';
import { useAuth } from '../../../AuthContext';
import axiosInstance from '../../../axiosInterceptor';

const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const LoadingDots = () => (
    <span className="inline-flex items-center">
        Sending
        <span className="inline-flex ml-[2px]">
            <span className="animate-loadingDot1">.</span>
            <span className="animate-loadingDot2">.</span>
            <span className="animate-loadingDot3">.</span>
        </span>
    </span>
);

const InviteModal = ({ isOpen, onClose, onSuccess, onError }) => {
    const [emailFields, setEmailFields] = useState([{ email: '', role: 'member', error: '' }]);
    const [loading, setLoading] = useState(false);
    const { workspaceContext } = useAuth();

    const addEmailField = () => {
        setEmailFields([...emailFields, { email: '', role: 'member', error: '' }]);
    };

    const handleInputChange = (index, field, value) => {
        const newFields = [...emailFields];
        newFields[index][field] = value;
        
        if (field === 'email') {
            newFields[index].error = value && !isValidEmail(value) 
                ? 'Please enter a valid email address' 
                : '';
        }
        
        setEmailFields(newFields);
    };

    const handleSendInvite = async () => {
        const invalidEmails = emailFields.filter(field => 
            field.email && !isValidEmail(field.email)
        );

        if (invalidEmails.length > 0) {
            onError('Please fix invalid email addresses');
            return;
        }

        try {
            setLoading(true);
            
            // Filter out any empty email fields
            const validInvites = emailFields.filter(field => field.email.trim() !== '');
            
            const response = await axiosInstance.post(`${process.env.REACT_APP_DATABASE_URL}/workspaces/sendInvite`, {
                invites: validInvites,
                workspaceId: workspaceContext._id // Assuming you have workspace context
            });

            if (response.status === 200) {
                onSuccess();
                onClose(); // Close modal on success
            }
        } catch (error) {
            onError(error.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    const hasValidationErrors = () => {
        return emailFields.some(field => 
            field.error || // has error message
            !field.email.trim() || // empty email
            !isValidEmail(field.email) // invalid email
        );
    };

    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-70 backdrop-blur-sm flex items-center justify-center z-50"
            onClickn={(e) => e.target === e.currentTarget && onClose()}
        >
            <div className="w-[638px] max-h-[90vh] bg-[#141516] border border-[#2C2E33] 
                rounded-tl-lg rounded-tr-none rounded-br-none rounded-bl-none 
                transform transition-all duration-200 ease-in-out"
            >
                <div className="h-[58px] flex items-center justify-between px-6 border-b border-[#2C2E33]">
                    <h2 className="text-white text-lg font-medium">Invite New Members</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-400 hover:text-white transition-colors"
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    </button>
                </div>
                <div className='p-6 flex flex-col gap-1'>
                    <div className="flex flex-col text-paragraph">
                        Invite via email
                    </div>
                    <div className="flex flex-col gap-4 text-caption">
                        Teammates will create an account via the sent email
                    </div>
                </div>
                <div className="p-6 border-t border-[#2C2E33] flex flex-col gap-4">
                    {emailFields.map((field, index) => (
                        <div key={index} className="flex flex-col gap-2">
                            <div className="flex gap-4">
                                <div className="flex-1">
                                    <input
                                        type="email"
                                        placeholder="Enter email address"
                                        value={field.email}
                                        onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                                        className={`w-full bg-[#0F1011] border ${
                                            field.error ? 'border-red-500' : 'border-[#2C2E33]'
                                        } rounded-md px-4 py-2 text-[#A0A0A0] focus:outline-none`}
                                    />
                                    {field.error && (
                                        <p className="text-red-500 text-xs mt-1">{field.error}</p>
                                    )}
                                </div>
                                <select 
                                    value={field.role}
                                    onChange={(e) => handleInputChange(index, 'role', e.target.value)}
                                    className="bg-[#0F1011] border border-[#2C2E33] rounded-md px-4 py-2 text-[#A0A0A0] focus:outline-none"
                                >
                                    <option value="admin">Admin</option>
                                    <option value="member">Member</option>
                                    <option value="manager">Manager</option>
                                </select>
                            </div>
                        </div>
                    ))}
                    <button
                        onClick={addEmailField}
                        className="text-[#E27AAB] text-left hover:text-[#ec8fb8] transition-colors"
                    >
                        + Add more emails
                    </button>
                </div>
                <div className="flex justify-end p-6 border-t border-[#2C2E33]">
                    <button
                        onClick={handleSendInvite}
                        disabled={loading || hasValidationErrors()}
                        className="bg-[#E27AAB] text-white rounded-md px-4 py-2 hover:bg-[#ec8fb8] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        {loading ? <LoadingDots/> : 'Send Invite'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InviteModal;