import { useState, Fragment } from "react";
import { Button } from "@mui/material";
import { PiUploadSimple, PiPlus } from "react-icons/pi";
import CircularProgress from "@mui/material/CircularProgress";
import OnboardingDialog from "../Dialog/Onboarding/OnboardingDialog";
import { uploadPRD } from "../../Actions/tcActions";
import { useDispatch, useSelector } from "react-redux";

const UploadFileModal = ({ handleAddDetails, open, onClose, apiNames }) => {
    const [productDescription, setProductDescription] = useState("");
    const [prdFile, setPrdFile] = useState(null);

    const dispatch = useDispatch();
    const { loading } = useSelector(
        (state) => state.apis
      );

    const handleFileUpload = (e) => {
        setPrdFile(e.target.files[0]);
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        dispatch(uploadPRD(formData));
    };

    return <OnboardingDialog width="65vw" height="75vh" open={open}
        body={<Fragment>
            <div className="py-8 px-12 w-full flex flex-col">
                <div
                    onClick={onClose}
                    className="origin-center rotate-45 cursor-pointer text-[#B0B0B0] self-end"
                >
                    <PiPlus size={20} />
                </div>
                <div className="font-semibold flex items-center text-h2">
                    Provide Your Application Details{" "}
                    <span className="text-[#E7E7E7] font-normal ml-3 text-sm">
                        (optional)
                    </span>
                </div>
                <div className="text-[#B0B0B0] text-sm mt-1">
                    Our AI agent will use this information to generate context-rich
                    test cases tailored to your specific API needs.
                </div>
                <textarea
                    onChange={(e) => {
                        setProductDescription(e.target.value);
                    }}
                    placeholder="Enter details about your product here"
                    className="rounded-md min-h-[35vh] w-full bg-[#0F1011] border border-1 border-[#2C2E33] text-white text-sm p-4 mt-4 focus:outline-none active:outline-none"
                />
                <div className="my-8 flex items-center justify-start">
                    {/* <div className="or-line"></div> */}
                    <div className="w-[48%] h-[1px] bg-[#2C2E33]" />
                    <div className="px-2">or</div>
                    <div className="w-[48%] h-[1px] bg-[#2C2E33]" />
                </div>
                <div className="cursor-pointer rounded-md bg-dashboard-dark-600 hover:bg-[#2D2F37] border border-dashboard-border-500 flex items-center px-3.5 py-2 text-sm max-w-fit">
                    <span className="mr-2">
                        <PiUploadSimple size={17} />
                    </span>
                    <input
                        className="absolute w-[140px] opacity-0 cursor-pointer z-20"
                        onChange={(e) => handleFileUpload(e)}
                        type="file"
                        accept=".pdf, .doc, .docx, .txt"
                        placeholder="Upload PRD File"
                    />
                    {prdFile ? (
                        <div className="cursor-pointer">{prdFile.name}</div>
                    ) : (
                        <div className="cursor-pointer">Upload PRD File</div>
                    )}
                </div>
            </div>
            <div className="p-4 pl-8 border border-t-1 border-[#2C2E33] flex justify-between w-full text-sm justify-self-end">
                {loading ? (
                    <div className="flex items-center gap-4">
                        <span className="text-[#E45799]">
                            <CircularProgress size={18} color="inherit" />
                        </span>
                        Uploading PRD File
                    </div>
                ) : (
                    <div></div>
                )}
                <div className="flex">
                    <Button
                        onClick={apiNames ? onClose : handleAddDetails}
                        sx={{
                            marginRight: '10px',
                            borderRadius: '4px',
                            backgroundColor: '#141516',
                            border: '1px solid #35383E',
                            padding: '6px 20px',
                            '&:hover': {
                                backgroundColor: '#2D2F37',
                            },
                            color: 'white',
                            textTransform: 'none',
                            fontWeight: '400',
                        }}
                    >
                        Skip
                    </Button>
                    <Button
                        sx={{
                            "&.Mui-disabled": {
                                background: "#222222",
                                color: "white",
                                border: "1px solid #222222",
                            },
                            borderRadius: "4px",
                            backgroundImage: 'linear-gradient(to bottom, #E27AAB, #D85C93)',
                            border: "1px solid #FF86BE",
                            padding: "6px 20px",
                            ":hover": {
                                backgroundImage: 'linear-gradient(to bottom, #DD6B9F, #E27AAB)',
                            },
                            color: "white",
                            textTransform: "unset",
                            fontWeight: "400",
                        }}
                        onClick={apiNames ? onClose : handleAddDetails}
                        {...(prdFile || productDescription
                            ? { disabled: false }
                            : { disabled: true })}
                        className="cursor-pointer rounded-md bg-[#D9509B] hover:bg-[#CC3F8D] border border-1 border-[#FF86BE] px-4 py-1.5"
                    >
                        Add Details
                    </Button>
                </div>
            </div>
        </Fragment>} />
}

export default UploadFileModal;