import { Dialog } from "@mui/material";

const OnboardingDialog = ({ width, body, open, onClose, height }) => {
    return <Dialog
        maxWidth="85vw"
        open={open}
        PaperProps={{
            style: {
                minHeight: height ?? "fit",
                width: width,
                backgroundColor: "#141516",
                color: "#fff",
                boxShadow: "none",
                borderRadius: "12px",
                border: "1px solid #2C2E33",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            },
        }}
        {...{ onClose }}
    >
        {body}
    </Dialog>
}

export default OnboardingDialog;