import {SignIn} from '../../components/SignIn/SignIn'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import NotFound from '../../components/NotFound/NotFound'
import { useAuth } from '../../AuthContext'
import Loader from '../../components/Loader/Loader'


export const JoinPage = () => {
    const { token } = useParams();
    const [loading, setLoading] = useState(true);
    const [valid, setValid] = useState(false);
    const [email, setEmail] = useState('');

    const { validateJoiningToken } = useAuth();

    const message = "Invalid token. Please check the link and try again."

    useEffect(() => {
        setLoading(true);
        validateJoiningToken(token).then(response => {
            console.log(response);
            if (response.success) {
                setEmail(response.email);
                setValid(true);
            } else {
                setValid(false);
            }
            setLoading(false);
        }).catch(() => {
            setValid(false);
            setLoading(false);
        });
    }, [token]);

    return(
        console.log(email),
        loading ? <Loader /> : (valid ? <SignIn join={true} email={email}/> : <NotFound message={message}/>)
    )
}