import SDKCard from './SDKCard/SDKCard';
import { PiCopy } from "react-icons/pi";
import nodeIcon from '../../../icons/node-icon.svg'
import pythonIcon from '../../../icons/python-icon.svg'
import javaIcon from '../../../icons/java-icon.svg'
import golangIcon from '../../../icons/golang-icon.svg'
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getAPICount } from '../../../Actions/tcActions';
import {CircularProgress} from '@mui/material';
import { useAuth } from '../../../AuthContext';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { copyToClipboard } from '../../../utils/common';
import SnackbarComp from '../../Snackbar/Snackbar';


const SDK = ({setPageStatus}) =>{

    const {t} = useTranslation();

    const [noKey, setNoKey] = useState(false)

    const dispatch = useDispatch();

    const { apikey, logout, getapikey } = useAuth();

    const {
        loading: apiLoading,
        error,
        userApiCount
    } = useSelector((state) => state.apis);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");

    const handleProceed = () => {
        setPageStatus({keyIntegrated: true, apiCollected: false, verificationCompleted: false});
    }

    useEffect(() => {

        (async () => {
            await getapikey();
        })();
        dispatch(getAPICount())
    }, [])

    useEffect(() => {
        if (error) {
            setAlertSeverity("error");
            setAlertMessage(error);
            setSnackbarOpen(true);
            dispatch({type: 'clearError'})
        }
    }, [error])

    const renderComponent = () => {
        setTimeout(() => {
            setNoKey(true)
        }, 4000);
        return noKey ? <div onClick={() => {dispatch(getAPICount()); setNoKey(false); setTimeout(() => {
            setNoKey(true)
        }, 4000);}} className="cursor-pointer py-1 px-4 mt-20 rounded-md bg-dashboard-dark-600 border border-dashboard-border-500 text-center">{t('actions.refresh_to_update_integration_status')}</div> : <div className="mt-20 flex items-center"><span className='text-[#D9509B] flex items-center mr-3'><CircularProgress size={20} color="inherit" /></span>Waiting for the API key to be added into your project</div>
    }

    const handleLogout = async () => {
        await logout();
    }


    return  <div className="px-16 pt-10 relative overflow-hidden h-[85vh]">
        <div className='flex gap-2 absolute bottom-2 right-2 text-dashboard-text-graylight'>
            <div className="border border-dashboard-border-500 rounded min-w-fit px-4 py-1 cursor-pointer"onClick={handleLogout}>{t('actions.logout')}</div>
            <div className="border border-dashboard-border-500 rounded min-w-fit px-4 py-1">{t('actions.need_help')}</div>
        </div>
    <div className="w-full flex flex-col items-center justify-between">
        <div className="text-[#F37BB3] text-h2 font-semibold">{t('headings.integrate_sdk')}</div>
        <div className="mt-24">{t('headings.get_started_apikey')}</div>
        <div className="rounded-md border border-dashboard-border-600 px-4 py-2.5 mt-3 w-1/2 flex justify-between items-center text-dashboard-text-graylight">
            {apikey}
            <span data-tooltip-content="Copy" data-tooltip-place="top" data-tooltip-id="copy">
                <PiCopy size={20} className='cursor-pointer hover:text-[#D9509B]' onClick={() => copyToClipboard(apikey, setSnackbarOpen, setAlertSeverity, setAlertMessage)} />
                <Tooltip id='copy' />
            </span>
        </div>
        <div className="mt-20">Select required SDK</div>
        <div className="grid grid-cols-3 gap-3 mt-4">
            <SDKCard lang={t('languages.nodejs')} image={nodeIcon} githubUrl="https://github.com/devzery/devzery_middleware_express"/>
            <SDKCard lang={t('languages.python')} image={pythonIcon} githubUrl="https://github.com/devzery/devzery_middleware"/>
            {/* <div className="justify-self-center self-center translate-x-1/2"> */}
                <SDKCard lang={t('languages.java')} image={javaIcon} githubUrl="https://github.com/devzery/devzery-middleware-springboot"/>
            {/* </div> */}
            {/* <SDKCard lang="golang" image={golangIcon} docsUrl="https://github.com/devzery/devzery_middleware" githubUrl="https://github.com/devzery/devzery_middleware"/> */}
        </div>
        {userApiCount?.data?.count > 0 ? 
        <div onClick={handleProceed} className="cursor-pointer py-1 px-4 mt-16 transition bg-gradient-to-b from-gradient-primary-from to-gradient-primary-to hover:from-gradient-primary-active-from hover:to-gradient-primary-active-to rounded border border-[#FF86BE] text-center">{t('actions.proceed_to_analysis')}</div> 
        : renderComponent()}
    </div>
    <SnackbarComp open={snackbarOpen} severity={alertSeverity} message={alertMessage} handleClose={() => setSnackbarOpen(false)} />

</div>
}

export default SDK