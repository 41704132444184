import React from "react";
import APICard from "../APICard/APICard";
import serverLogo from "../logo2.png";
import ReactMarkdown from "react-markdown";
import { useState } from "react";
import Right from "../../../icons/chevron-right.svg";
import Left from "../../../icons/chevron-left.svg";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";
import ReqResBody from "../../ReqResBody/ReqResBody";

const ChatUI = ({ handleLogout, messages, sendMessage, allApis, setPageStatus, input, setInput, enableMessage, setMessages, setAllApis, setStatus, setIsFirstMessage }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const totalPages = Math.ceil(allApis?.length / itemsPerPage);
  const startIdx = (currentPage - 1) * itemsPerPage;
  const [showRequest, setShowRequest] = useState(false);
  const [showResponse, setShowResponse] = useState(false);

  const currentApis = allApis?.slice(
    startIdx,
    startIdx + itemsPerPage
  );

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleRemoveApiAndResetMessages = () => {
    setAllApis((prevApis) => {
      // Calculate the index of the first element of the current page in allApis
      const indexToRemove = startIdx;

      // Create a new array excluding the element at indexToRemove
      // console.log("indexToRemove", indexToRemove);
      // console.log(allApis[0]?.api?.name)
      const updatedApis = prevApis.filter((_, index) => index !== indexToRemove);
      if (allApis && allApis.length < 2) {
        setStatus("complete")
      }
      return updatedApis;
    });

    setMessages([]); // Reset messages to an empty array
    setIsFirstMessage(true); // Reset isFirstMessage to true
    // If the current page becomes invalid (e.g., when removing the last item on a page), go to the previous page
    if (startIdx >= allApis?.length - itemsPerPage && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };


  const components = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "");
      return !inline && match ? (
        <SyntaxHighlighter
          style={materialDark}
          language={match[1]}
          PreTag="div"
          {...props}
        >
          {String(children).replace(/\n$/, "")}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
  };

  const customStyle = {
    color: '#E27AAB'
  };


  return (
    <div className="bg-[#080808] p-4 grid grid-cols-10 w-full  overflow-x-hidden no-scrollbar gap-4">
      <div className="col-span-3 rounded-lg bg-[#17181A] text-white flex flex-col items-center px-8 h-[83vh]">
        <div className="text-center my-8">
          Our AI agent needs your help with verification
        </div>
        <div className="w-full flex flex-col">
          {currentApis &&
            currentApis.map((api, index) => (
              // <APICard key={api.api.id} item={api.api} index={index} />
              <APICard item={api.api} index={allApis[0]?.api?.id} />
            ))}
        </div>
        <div className="flex items-center justify-self-end self-end mb-8">
          <div className="text-[#EFEFEF] mr-2 text-sm">
            {" "}
            {startIdx + 1 === allApis?.length
              ? startIdx + 1
              : (startIdx + itemsPerPage) >
                allApis?.length
                ? allApis?.length
                : startIdx + 1 + "-" + (startIdx + itemsPerPage)}{" "}
            of {allApis?.length}
          </div>
          <div className="flex text-[#EFEFEF]">
            <div
              className="rounded-md flex items-center border border-1 cursor-pointer border-[#3E4045] mr-3 px-2 py-1"
              onClick={handlePrevPage}
            >
              <img src={Left} />
            </div>
            <div
              className="rounded-md flex items-center border border-1 cursor-pointer border-[#3E4045] p-2"
              onClick={handleNextPage}
            >
              <img src={Right} />
            </div>
          </div>
        </div>
        <div onClick={() => setPageStatus({ keyIntegrated: false, apiCollected: false, verificationCompleted: true })} className="bg-[#D9509B] border border-1 border-[#FF86BE] justify-self-end rounded-lg px-6 py-2 cursor-pointer">
          Confirm API verification
        </div>
      </div>
      <div className="col-span-7 flex flex-col justify-between items-center pb-8 h-[83vh]">
        <div className="text-[#E7E7E7] bg-[#26282F] border border-1 border-[#2C2E33] self-end my-4 mr-4 rounded min-w-fit px-4 py-0.5 cursor-pointer" onClick={handleLogout}>Log Out</div>
        <div className="chat-box w-full flex flex-col no-scrollbar">
          {messages.map((msg, index) => {
            if (msg.message === "Nothing else is possible") { handleRemoveApiAndResetMessages(); };
            return (<div className={msg.sender === "Server" ? "flex items-center" : " self-end justify-end text-right"}>
              {msg.sender === "Server" ? <img
                src={serverLogo}
                alt="Server Logo"
                className="w-[30px] h-[30px] mr-2"
              /> : ""}
              <div
                key={index}
                className={`rounded-lg border border-1 border-[#2E3138] px-4 py-2.5 text-[#ffffff] max-h-[80vh] overflow-y-auto no-scrollbar max-w-fit bg-inherit ${msg.sender === "Server"
                  ? " self-start justify-start"
                  : " self-end justify-end text-right"
                  }`}
              >
                {msg.sender === "Server" ? (
                  <>
                    {typeof msg.message === 'string' ? (
                      <>
                        <ReactMarkdown
                          components={{
                            ...components,
                            p: ({ children }) => {
                              if (typeof children === 'string') {
                                // Split the text by newlines to handle each line separately
                                const lines = children.split('\n');
                                return (
                                  <p>
                                    {lines.map((line, i) => {
                                      if (line.startsWith('API Name:')) {
                                        return (
                                          <p key={i}>
                                            <span style={customStyle}>API Name:</span>
                                            {line.substring('API Name:'.length)}
                                          </p>
                                        );
                                      }
                                      if (line.startsWith('Endpoint:')) {
                                        return (
                                          <p key={i}>
                                            <span style={customStyle}>Endpoint:</span>
                                            {line.substring('Endpoint:'.length)}
                                          </p>
                                        );
                                      }
                                      return <p key={i}>{line}</p>;
                                    })}
                                  </p>
                                );
                              }
                              return <p>{children}</p>;
                            }
                          }}
                          remarkPlugins={[remarkGfm]}
                          className="markdown-content"
                        >
                          {msg.message}
                        </ReactMarkdown>
                        {index === 0 && (
                          <>
                            <div className="flex gap-2 mt-2">
                              <button
                                className="px-3 py-1 bg-[#D9509B] rounded text-sm"
                                onClick={() => { setShowRequest(true); setShowResponse(false); }}
                              >
                                View Request
                              </button>
                              <button
                                className="px-3 py-1 bg-[#2E3138] rounded text-sm"
                                onClick={() => { setShowResponse(true); setShowRequest(false) }}
                              >
                                View Expected Response
                              </button>
                            </div>

                            {showRequest && (
                              <div className="mt-2">
                                <ReqResBody
                                  title="Request"
                                  string={allApis[0]?.api?.source?.request?.body || {}}
                                  status={null}
                                />
                              </div>
                            )}

                            {showResponse && (
                              <div className="mt-2">
                                <ReqResBody
                                  title="Expected Response"
                                  string={allApis[0]?.api?.source?.response?.body || {}}
                                  status={allApis[0]?.api?.source?.response?.status}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <ReactMarkdown>{"Not a string"}</ReactMarkdown>
                    )}
                  </>
                ) : (
                  <strong>{msg.sender === "Server" ? msg.sender : ""}</strong>
                )}
                {msg.sender === "User" ? <p className="self-end justify-end text-right">{msg.message}</p> : " "}
              </div>
            </div>)
          })}
        </div>
        <form
          className="bg-[#17181A] border border-1 border-[#2E3138] flex items-center p-2 w-[80%] rounded-md"
          onSubmit={sendMessage}
        >
          <textarea
            value={input}
            className="bg-inherit text-[#ffffff] flex items-center w-[90%]"
            placeholder="Enter your message..."
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                sendMessage(e);
              }
            }}
            disabled={!enableMessage}
          />
          <button
            className="px-2 py-1 bg-[#D9509B] rounded-md w-[10%] text-white"
            type="submit"
            disabled={!enableMessage}
          >
            Send
          </button>
        </form>
      </div>
    </div >
  );
}

export default ChatUI;