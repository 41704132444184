import { useEffect, useState } from "react";
import { LuTrash } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { addBaseURL } from "../../../Actions/tcActions";
import SnackbarComp from "../../Snackbar/Snackbar";

const Table = ({ data, setData, selected, setSelected }) => {
    const dispatch = useDispatch();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");

    useEffect(() => {
        if (data && data[data.length - 1].source.length > 0 && data[data.length - 1].baseUrl.length > 0) {
            setData([...data, { source: '', baseUrl: '' }])
        }
    }, [data])

    const handleSourceChange = (value, ind) => {
        let temp = { ...data[ind] }
        temp.source = value
        data[ind] = temp
        setData([...data])
    }

    const handleDelete = (index) => {
        if (data.length > 1) {
            let temp = [...data]
            temp.splice(index, 1)
            setData(temp)
        }
        // let formattedData = temp.filter((item) => item.source !== '' && item.baseUrl !== '')
        // dispatch(addBaseURL(formattedData));
    }

    const handleCheck = (i) => {
        if (selected == i) setSelected(null)
        else if (data[i].source !== '' && data[i].url !== '') {
          setSelected(i)
        } else {
          setAlertMessage("Please fill the details for the selected API")
          setAlertSeverity("error")
          setSnackbarOpen(true)
        }
      }

    const renderBaseUrls = () => {
        const arr = [];
        for (let i = 0; i < data?.length; i++) {
            arr.push(
                <div className="grid grid-cols-12 border-b border-[#2C2E33]">
                    <div className="col-span-1 flex items-center justify-center"><input
                        // style={{ marginRight: "5px" }}
                        type="checkbox"
                        id="apiSelected"
                        name="apiSelected"
                        onClick={() => handleCheck(i)}
                        checked={selected == i ? true : false}
                    /></div>
                    <input value={data[i].source} onChange={(e) => handleSourceChange(e.target.value, i)} className="col-span-4 bg-inherit px-3 py-2 border-x border-[#2C2E33] focus:outline-none active:outline-none" />
                    <input value={data[i].baseUrl} onChange={(e) => {
                        let temp = { ...data[i] }
                        temp.baseUrl = e.target.value
                        data[i] = temp
                        setData([...data])
                    }} className="col-span-6 bg-inherit px-3 py-2 focus:outline-none active:outline-none" />
                    <div className="col-span-1 text-[#494949] cursor-pointer flex items-center justify-center border-l border-[#2C2E33] cursor-pointer" ><span onClick={() => handleDelete(i)} className="hover:bg-[#1D1E20] rounded-md p-1.5 hover:text-[#F87171]">
                        <LuTrash />
                    </span></div>
                </div>
            );
        }

        return arr;
    }

    return <div className="h-full overflow-y-auto no-scrollbar">
        {renderBaseUrls()}
        <SnackbarComp open={snackbarOpen} setOpen={setSnackbarOpen} severity={alertSeverity} message={alertMessage} />
    </div>;
}

export default Table;