import { useState, useEffect, Fragment } from "react";
import APICard from "./APICard/APICard";
import {
  addNamedApi,
  getUserApis,
  deleteNamedUserApis,
} from "../../../Actions/tcActions";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./Loader/Loader";
import { useAuth } from "../../../AuthContext";
import SnackbarComp from "../../Snackbar/Snackbar";
import Pagination from "../../Pagination/Pagination";
import AddAPIModal from "../../AddAPIModal/AddAPIModal";
import Buttons from "../Buttons/Buttons";
import UploadFileModal from "../../UploadFileModal/UploadFileModal";


const CollectAPI = ({ setPageStatus }) => {
  const [addApiModalOpen, setAddApiModalOpen] = useState(false);
  const [headers, setHeaders] = useState([["", ""], ["", ""], ["", ""]]);
  const [apiDetails, setApiDetails] = useState({
    name: null,
    method: "get",
    path: null,
    serverName: null,
    body: null,
  });
  const [loadingType, setLoadingType] = useState("Uploading file");
  const [unSelectedApis, setUnSelectedApis] = useState([]);
  const [totalApis, setTotalApis] = useState([]);
  const [apiCollected, setApiCollected] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  const { loading, responseBody, message, error } = useSelector(
    (state) => state.apis
  );
  const {
    loading: sequenceLoading,
    apiNames,
  } = useSelector((state) => state.sequence);
  const {
    loading: userLoading,
    // user,
    userError,
  } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { user, logout, workspaceContext, getworkspaces } = useAuth();
  const [modalOpen, setModalOpen] = useState(apiNames ? false : true);

  const handleAddDetails = async () => {
    setLoadingType("Collecting Unique APIs");
    setApiCollected(true);
    if (workspaceContext?._id) {
      dispatch(await getUserApis(workspaceContext?._id));
    } else {
      await getworkspaces();
    }
    setModalOpen(false);
  };

  useEffect(() => {
    if (workspaceContext?._id) {
      dispatch(getUserApis(workspaceContext?._id));
    }
  }, [workspaceContext]);

  useEffect(() => {
    if (apiNames) {
      setTotalApis(apiNames);
    }
  }, [apiNames]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const totalPages = Math.ceil(totalApis?.length / itemsPerPage);
  const startIdx = (currentPage - 1) * itemsPerPage;
  const currentApis = totalApis?.slice(startIdx, startIdx + itemsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleAddApi = (e) => {
    e.preventDefault();
    setLoadingType("Adding API");
    let formattedHeaders = {};
    headers.forEach((header) => {
      if (header[0] !== "" && header[1] !== "") {
        formattedHeaders[header[0]] = header[1];
      }
    });
    dispatch(
      addNamedApi({
        apiName: apiDetails.name,
        apiMethod: apiDetails.method,
        apiEndpoint: apiDetails.path,
        serverName: apiDetails.serverName,
        headers: formattedHeaders,
        body: JSON.parse(apiDetails.body),
        responseBody,
      })
    ).then(() => {
      setTotalApis([
        ...totalApis,
        {
          api_name: apiDetails.name,
          request: {
            body: apiDetails.body,
            headers: formattedHeaders,
            method: apiDetails.method,
            path: apiDetails.path,
          },
          serverName: apiDetails.serverName,
        },
      ]);
      // Once the first dispatch is done, dispatch getUserApis
      // dispatch(getUserApis(user?._id));
    });
    setAddApiModalOpen(false);
  };

  useEffect(() => {
    if (error || userError) {
      // console.log("error", error);
      setAlertSeverity("error");
      setAlertMessage(userError ? userError : `Error adding the API: ${error}`);
      setSnackbarOpen(true);
      dispatch({
        type: "clearError",
      });
    }
  }, [error, userError, loading]);

  useEffect(() => {
    if (message && message !== "APIs deleted successfully") {
      // console.log("message", message);
      setAlertSeverity("success");
      setAlertMessage(message);
      setSnackbarOpen(true);
      dispatch({
        type: "clearMessage",
      });
    }
  }, [message]);


  const handleProceed = () => {
    setPageStatus({
      keyIntegrated: false,
      apiCollected: true,
      verificationCompleted: false,
    });
    let copy = [];
    if (unSelectedApis.length > 0) {
      totalApis.forEach((api, index) => {
        if (!unSelectedApis.includes(api)) {
          copy.push(api);
        }
      });
      dispatch(deleteNamedUserApis(copy));
    } else {
      dispatch(deleteNamedUserApis(totalApis));
    }
  };

  const handleLogout = async () => {
    await logout();
  }

  useEffect(() => {
    if (loadingType === "Collecting Unique APIs" && apiNames) {
      setTimeout(() => {
        setApiCollected(false);
      }, 1500);
    }
  }, [apiNames])

  const bottomButtonsConfig = [
    { title: 'Need Help?' },
    { title: 'Logout', action: handleLogout },
  ]

  const topButtonsConfig = [
    { title: 'Add API', action: () => setAddApiModalOpen(true) },
    { title: 'Add Product Specifications', action: () => setModalOpen(true) },
  ]

  return <Fragment>
    {(loading && loadingType !== "Uploading file") || (sequenceLoading || apiCollected) ? (
      <Loader type={loadingType} setModalOpen={setModalOpen} apiNames={apiNames} setApiCollected={setApiCollected} />
    ) : (!apiCollected && apiNames) && <div className="px-16 pt-10 w-full overflow-x-hidden h-[83vh] flex flex-col items-center no-scrollbar relative">
      <Buttons config={bottomButtonsConfig} extraClasses="bottom-0 right-4" />
      <Buttons config={topButtonsConfig} extraClasses="relative self-end translate-x-10" />
      <div className="my-8">Please unselect APIs in the list that are not required for API Analysis.</div>
      <div className="w-[70vw] max-w-[1010px]">
        <div className="border border-1 border-[#3E4045] rounded-md w-full">
          <div className="flex justify-between p-4 border-b border-[#3E4045] text-paragraph font-semibold">
            <div className="">API List</div>
            <div>
              Number of APIs captured{" "}
              <span className="ml-3 px-3 py-1.5 border border-dashboard-text-pink rounded-md text-sm font-regular">
                {totalApis?.length}
              </span>
            </div>
          </div>
          <div className="grid grid-rows-4 grid-cols-2">
            {currentApis.map((api, index) => (
              <APICard
                api={api}
                index={index}
                unSelectedApis={unSelectedApis}
                currentPage={currentPage}
              />
            ))}
          </div>
        </div>
        <Pagination itemsPerPage={8} length={totalApis?.length} handleNextPage={handleNextPage} handlePrevPage={handlePrevPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        {/* <div className="flex flex-col self-center items-center mt-8">
            <div>Confirm if all APIs are collected</div>
            <div className="flex mt-3">
              <div onClick={() => setApiCollected(true)} className="bg-[#26282F] border border-1 border-[#35383E] rounded-md px-6 py-1 mr-2 cursor-pointer">Yes</div>
              <div className="bg-[#30333C] border border-1 border-[#484B52] rounded-md px-6 py-1">No</div>
            </div>
          </div> */}
      </div>
      <div
        onClick={handleProceed}
        className="cursor-pointer py-2 px-5 mt-8 rounded bg-gradient-to-b from-gradient-primary-from to-gradient-primary-to hover:from-gradient-primary-active-from hover:to-gradient-primary-active-to text-center self-center"
      >
        Proceed to API verification
      </div>
      <AddAPIModal modalOpen={addApiModalOpen} width="85vw" setModalOpen={setAddApiModalOpen} apiDetails={apiDetails} responseBody={responseBody} setApiDetails={setApiDetails} headers={headers} setHeaders={setHeaders} handleAddAPI={handleAddApi} setLoadingType={setLoadingType} />
    </div>
    }
    <UploadFileModal handleAddDetails={handleAddDetails} open={modalOpen} onClose={() => setModalOpen(false)} apiNames={apiNames} />
    <SnackbarComp open={snackbarOpen} severity={alertSeverity} message={alertMessage} handleClose={() => setSnackbarOpen(false)} />

  </Fragment>
};

export default CollectAPI;
