import React, {useState} from 'react';
import { HiOutlineCube } from "react-icons/hi";
import { BsThreeDotsVertical } from "react-icons/bs";
import Dropdown from './Dropdown';
import { useAuth } from '../../../AuthContext';
import { useNavigate } from 'react-router-dom';


const WorkspaceCard = ({ workspace }) => {
    const navigate = useNavigate();
    const [showDropdown, setShowDropdown] = useState(false);
    const { setWorkspacesContext, workspaceContext } = useAuth();


    const dropDownOptions = [
        {
           label: 'Delete',
           textColor: '#B0B0B0',
           onClick: () => console.log('Delete')
        },
        {
            label: 'Disable',
            textColor: '#B0B0B0',
            onClick: () => console.log('Disable')
        },
    ]

    const handleOpenWorkspace = (workspace) => {
        setWorkspacesContext(workspace);
        navigate('/test-suite');
    }

    return (
        <div className="w-[342px] h-[130px] p-[16px] flex flex-col justify-between rounded-tl-[4px] border-t border-l-0 border-r-0 border-b-0 border-[#282C33] bg-[#0F1011] hover:bg-[#161819] transition-colors">
            <div className="flex items-center justify-between relative">
                <div className="flex items-center gap-2">
                    <HiOutlineCube className="text-[#4B4F59] text-xl" />
                    <span className="text-white font-medium">{workspace.name}</span>
                </div>
                <div className="relative">
                    <button className="p-2 hover:bg-[#282C33] rounded-full transition-colors" onClick={() => setShowDropdown(!showDropdown)}>
                        <BsThreeDotsVertical className="text-[#4B4F59]" />
                    </button>
                    {showDropdown && (
                        <div className="absolute right-0 top-full mt-2 w-48 bg-[#1D1E20] rounded-md shadow-lg z-50">
                            <Dropdown options={dropDownOptions}/>
                        </div>
                    )}
                </div>
            </div>
            
            <button className="w-[58px] h-[20px] py-1 px-0 bg-[#1D1E20] text-caption text-white rounded-[4px] border-t border-l-0 border-r-0 border-b-0.5 border-[#282C33] hover:bg-[#343841] transition-colors flex items-center justify-center" onClick={() => handleOpenWorkspace(workspace)}>
                Open
            </button>
        </div>
    );
};

export default WorkspaceCard;