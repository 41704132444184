import React, { useEffect, useState } from "react";
import "./BugTracker.css";
import Layout from '../Pagelayout/Layout';
import { Controls } from '../TestSuite/Controls';
import TestCard from "./TestCard";
import { Panel, PanelGroup } from 'react-resizable-panels';
import CoverageSidebar from "../TestSuite/CoverageSideBar";
import { fetchBugs } from "../../Actions/tcActions";
import { useDispatch, useSelector } from "react-redux";

/*

BugTracker component that renders the bug tracker page of the application.
It uses the useState hook to manage the state of the modal.
It contains a table that displays the list of bugs.
It uses the IssuesTable component to display the bugs.
It uses the Dialog component from Material-UI to display the modal.
It contains buttons to view base URLs and add new test cases.

*/
const BugTracker = () => {
  const [selectedOptions, setSelectedOptions] = useState({ priority: [], status: [] });

  const {
    loading: testsCasesLoading,
    tests, 
    bugs
  } = useSelector((state) => state.testCases);
  const [search, setSearch] = useState(null); // Use the search state to store the search query
  const [data, setData] = useState(null)

  const dispatch = useDispatch();

  const handleSearch = (value) => {
    setSearch(value);
    const searchedData = data?.original.filter((item) =>
      (item.title.toLowerCase().includes(value.toLowerCase()))
    );
    setData({ ...data, current: value === '' ? data?.original : searchedData })
  }

  useEffect(() => {
    if (bugs) {
      setData({ original: bugs, current: bugs });
    }
  }, [bugs]);

  useEffect(() => {
    dispatch(fetchBugs());
  },[])


  const controlComponentsConfig = [
    { type: "TestRunSelector", group: "first" },
    { type: "SearchBar", props: { search: search, handleSearch: handleSearch, type: 'bugs' }, group: "first" },
    { type: "FilterButton", group: "second", props: { selectedOptions: selectedOptions, setSelectedOptions: setSelectedOptions } },
    { type: "SortButton", group: "second" },
    { type: "ShareButton", group: "second" }
  ];

  useEffect(() => {
    let filteredData = data?.original;
    if (selectedOptions.priority.length > 0) {
      filteredData = filteredData.filter((item) => selectedOptions.priority.includes(item.priority));
    } 
    if (selectedOptions.status.length > 0) {
      filteredData = filteredData.filter((item) => selectedOptions.status.includes("Passed") ? item.status === "Closed" : item.status === "Open");
    }
    setData({ ...data, current: filteredData });
  }, [selectedOptions])

  return (
    <Layout>
      <Controls components={controlComponentsConfig} />
      <div className="flex flex-col h-full">
        <PanelGroup direction="horizontal" className="flex-1 overflow-auto">
          <Panel minSize={80} className="overflow-auto pb-8 no-scrollbar">
            <div className="flex-1 overflow-auto px-2 py-4 h-full no-scrollbar">
              {data?.current?.map((bug, index) => (
                  <TestCard
                    index={index+1}
                    bug={bug}
                  />
                ))}
            </div>
          </Panel>
          <Panel minSize={20} className="overflow-auto no-scrollbar">
            <CoverageSidebar coverageTypes={['bug', 'priority']} />
          </Panel>
        </PanelGroup>
      </div>
    </Layout>
  );
};

export default BugTracker;