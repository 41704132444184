import React from 'react'
import './Integrations.css'
import IntegrationCard from './IntegrationCard/IntegrationCard'
import GitHubIcon from './../../icons/github.svg'
import JiraIcon from './../../icons/jira.svg'
import JenkinsIcon from './../../icons/jenkins.svg'
// import Navbar from '../Navbar/Navbar'
import Layout from '../Pagelayout/Layout';


/*

It renders the integrations page which displays the available and integrated integrations.
The Integrations component uses the IntegrationCard component to display the integrations.
The component uses the types array to store the types of integrations: GitHub, Jira, and Jenkins.

*/
const types = [
    {
        icon: GitHubIcon,
        title: 'GitHub'
    },
    {
        icon: JiraIcon,
        title: 'Jira'
    },
    {
        icon: JenkinsIcon,
        title: 'Jenkins'
    }
]

const Integrations = () => {
    const [selected, setSelected] = React.useState(false) // Use the selected state to determine which button is selected
    const handleClick = (e) => {
        if (e.target.innerText === "Available") {
            setSelected(false)
        }
        else {
            setSelected(true)
        }
    }
    return (
        <Layout>
            {/* <div className='integrations-container'>
                <div className='integrations-nav'><Navbar /></div> */}
                <div className='integrations-page'>
                    <div className="integrations-top">
                        <div className="select-workspace">
                            <select>
                                <option value="Select Workspace" disabled selected>Select Workspace</option>
                            </select>
                        </div>
                    </div>
                    <div className="integrations-middle">
                        <div className="integrations-status">
                            <button
                                className={`availBtn${selected === false ? "-selected" : " "}`}
                                onClick={handleClick}
                            >
                                Available
                            </button>
                            <button
                                className={`intBtn${selected === true ? "-selected" : " "}`}
                                onClick={handleClick}
                            >
                                Integrated
                            </button>
                        </div>
                    </div>
                    <div className="integrations-bottom">
                        <div className="integrations-tool">
                            <div className="ci-cd-integration">
                                <div className="ci-cd-header">
                                    <p>CI/CD Integrations</p>
                                </div>
                                <div className="ci-cd-tools">
                                    <IntegrationCard
                                        icon={types[2].icon}
                                        title={types[2].title}
                                    />
                                    <IntegrationCard
                                        icon={types[0].icon}
                                        title={types[0].title}
                                    />

                                </div>
                            </div>
                            <div className="project-management">
                                <div className="pm-header">
                                    <p>Project Management</p>
                                </div>
                                <div className="pm-tools">
                                    <IntegrationCard
                                        icon={types[1].icon}
                                        title={types[1].title}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                </Layout>
            )
}

            export default Integrations
