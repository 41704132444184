import { SearchIcon } from "../../icons";

const SearchBar = ({ search, handleSearch, className, placeholder }) => {
    return (
        <div className={`relative ${className}`}>
            <input
                value={search || ''}
                onChange={(e) => handleSearch(e.target.value)}
                type="text"
                placeholder={placeholder || "Search"}
                className="h-[36px] w-full bg-[#0F1011] px-10 py-2 
                text-[#A0A0A0] rounded-md border border-[#2C2E33]
                focus:outline-none focus:border-[#E27AAB] focus:ring-1 focus:ring-[#E27AAB]
                transition-colors duration-200"
            />
            <SearchIcon 
                className="absolute left-3 top-1/2 transform -translate-y-1/2 
                text-[#4B4F59] w-4 h-4" 
            />
        </div>
    );
};

export default SearchBar;