import { useState } from 'react';
import { RefreshIcon, CoverageIcon } from '../../icons';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import EmptyState from '../EmptyStates/EmptyState';

ChartJS.register(ArcElement, Tooltip, Legend);

const CoverageChart = ({
  data,
  totalData,
  tooltipBackgroundColors = {},
  tooltipTitleColors = {}
}) => {
  // Check for empty or invalid data
  /* const total = data?.reduce((acc, curr) => acc + curr, 0);
  if (!data || data.length === 0 || total === 0) {
    return <div className="text-center text-gray-500">{emptyStateMessage}</div>;
  }
  if (emptyState) {
    return <>
      <EmptyState
        altText="Pie Chart"
        message={
          <>
            Whoops! No data<br />
            available at the moment.
          </>
        }
      />
    </>;
  } */

  // Prepare chart data and options
  /* const chartData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: colors,
        borderColor: colors,
        hoverOffset: 4,
      },
    ],
  }; */

  const chartData = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: data.map((item) => Math.round(item.value)),
        backgroundColor: data.map((item) => item.color),
        borderColor: data.map((item) => item.color),
        borderWidth: 1,
        dataVisibility: new Array(data.length).fill(true),
      },
    ],
  };

  /* const chartOptions = {
    cutout,
    plugins: {
      tooltip: {
        backgroundColor: (context) => {
          const label = context.tooltip.dataPoints[0]?.label || '';
          return tooltipBackgroundColors[label] || '#fff';
        },
        displayColors: false,
        titleColor: (context) => {
          const label = context.tooltip.dataPoints[0]?.label || '';
          return tooltipTitleColors[label] || '#000';
        },
        callbacks: {
          label: (context) => {
            const value = context.raw;
            const percentage = Math.round((value / total) * 100);
            return `${percentage}%`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    elements: {
      arc: {
        hoverRadius: 5,
        borderWidth: 0.5,
      },
    },
  }; */

  const chartOptions = {
    plugins: {
      responsive: true,
      legend: {
        display: false,
      },
    },
    cutout: data.map((item) => item.cutout)
  };

  return (
    <div className="mx-auto">
      <div className='px-4 relative'>
        <CoverageIcon data={chartData} options={chartOptions} />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center">
          <span className="text-2xl font-bold text-dashboard-text-gray">{totalData.value}</span>
          <span className={`text-sm text-${totalData.color}`}>{totalData.label}</span>        
        </div>
      </div>
      {/* <div className="absolute top-[40%] left-1/2 -translate-x-1/2 text-center"> */}
    </div>
  );
};

const CoverageHeader = ({
  title,
  onRefresh,
}) => {
  const [isSpinning, setIsSpinning] = useState(false);

  const handleRefresh = async () => {
    setIsSpinning(true);
    if (onRefresh) {
      try {
        await onRefresh();
      } finally {
        setTimeout(() => setIsSpinning(false), 1000);
      }
    }
  };

  return (
    <h2 className="text-md font-semibold mb-4 flex items-center justify-between text-dashboard-text-pink">
      {title}
      <div
        onClick={handleRefresh}
        className={`inline-block ${isSpinning ? "animate-spin" : ""}`}
      >
        <RefreshIcon className="h-4 w-4 text-dashboard-text-gray cursor-pointer" />
      </div>
    </h2>
  );
};

const CoverageBreakdown = ({ data }) => {
  // Calculate the total sum of data values
  const total = data.reduce((sum, item) => sum + item.value, 0);

  return (
    <div className="mt-4 space-y-2">
      {data.map((item) => {
        // Calculate the percentage for each value
        const percentage = total > 0 ? Math.round((item.value / total) * 100) : 0;

        return (
          <div key={item.label} className="flex items-center justify-between">
            <div className="flex items-center">
              {item.color && (
                <span
                  className="inline-block w-2.5 h-2.5 rounded-full mr-2"
                  style={{ backgroundColor: item.color }}
                />
              )}
              <span className="text-dashboard-text-gray">{item.label}</span>
            </div>
            <span className="text-dashboard-text-gray">{percentage}%</span>
          </div>
        );
      })}
    </div>
  );
};

const Coverage = ({
  title,
  data,
  totalData,
  tooltipBackgroundColors = {},
  tooltipTitleColors = {},
  emptyState,
  emptyStateMessage,
  onRefresh
}) => {
  return (
    <div className="bg-dashboard-dark-700 rounded-lg px-4 py-2 flex-1 border border-dashboard-dark-500">
      {/* Header Section */}
      <CoverageHeader title={title} onRefresh={onRefresh} />

      {/* Conditional Rendering */}
      {!emptyState ? (
        <div className="relative pt-4">
          {/* Circular Chart */}
          <CoverageChart
            data={data}
            totalData={totalData}
            tooltipBackgroundColors={tooltipBackgroundColors}
            tooltipTitleColors={tooltipTitleColors}
          />
          {/* Percentage Breakdown */}
          <CoverageBreakdown data={data} />
        </div>
      ) : (
        <EmptyState
          altText="Pie Chart"
          message={
            <>
              Whoops! No data
              <br />
              available at the moment.
            </>
          }
        />
      )}
    </div>
  );
};

export default Coverage;