export const getMethodClasses = (method) => {
    switch (method?.toLowerCase()) {
        case "post":
            return "bg-[#816F33]/40 text-[#FCD34D]";
        case "get":
            return "bg-[#31744A]/40 text-[#4ADE80]";
        case "put":
            return "bg-[#2F5A83]/40 text-[#44A5FF]";
        case "delete":
            return "bg-[#804343]/40 text-[#F87171]";
        case "patch":
            return "bg-[#6B4FBB4D] text-[#96A7FF]"
    }
};

export const getStatusStyles = (status, ignore) => {
    const styles = {
      Open: {
        container: "bg-[#261322] text-white",
        dot: "bg-[#E27AAB]"
      },
      Closed: {
        container: "bg-[#0B1C2B] text-white",
        dot: "bg-[#44A5FF]"
      },
      default: {
        container: "bg-dashboard-dark-600 text-dashboard-text-graylight",
        dot: "bg-dashboard-text-gray"
      }
    };
  
    return ignore ? styles.default : (styles[status] || styles.default);
  };