import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { useState } from "react";

const EllipsisDropdown = ({ config, props }) => {
    const [openMenu, setOpenMenu] = useState(false);

    return <div className={`relative cursor-pointer z-10`}>
        <IoEllipsisHorizontalSharp onClick={() => setOpenMenu(!openMenu)} size={20} />
        {openMenu && <div className='bg-[#141516] rounded-lg border border-dashboard-dark-400 p-2 absolute top-6 right-1'>
            {config.map((item, index) => <div className='px-4 py-1.5 rounded-xl flex gap-2 items-center text-[#B0B0B0] hover:text-white hover:bg-dashboard-dark-600' onClick={() => {
                setOpenMenu(!openMenu);
                props ? item.action(...props) : item.action();
            }}>
                <span className='text-[#494949] hover:text-white'>
                    <item.icon />
                </span>
                {item.title}
            </div>)}
        </div>}
    </div>
}

export default EllipsisDropdown;