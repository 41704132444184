import SettingsHeading from '../Components/SettingsHeading';
import { Panel } from 'react-resizable-panels';
import { LuTrash } from "react-icons/lu";
import { HiMiniPlusSmall } from 'react-icons/hi2';
import { useAuth } from '../../../AuthContext';
import { useState } from 'react';
import { updateWorkspace } from '../../../Actions/tcActions';
import { useDispatch } from 'react-redux';


const WorkspaceSettings = () => {
    const dispatch = useDispatch();
    const { workspaceContext, workspaces } = useAuth();
    const [isEditing, setIsEditing] = useState(false);
    const [workspaceName, setWorkspaceName] = useState(workspaceContext.name);

    const handleEditClick = () => {
        setIsEditing(!isEditing);
        if (isEditing) {
            // handle save
            dispatch(updateWorkspace(workspaceContext._id, workspaceName));
        }
    };

    const handleNameChange = (e) => {
        setWorkspaceName(e.target.value);
    };

    return (
        <div className="">
            <Panel>
                <SettingsHeading variant='workspace' />
            </Panel>
            <Panel>
                <div className="flex flex-col gap-16">
                    <div>
                        <div className="text-[#B0B0B0] mb-6 text-lg">
                            Workspace Name
                        </div>
                        <div className="w-full flex gap-6 items-center">
                            <div className="rounded-md w-[548px] bg-[#0F1011] border border-[#2C2E33] flex justify-between items-center gap-2 pr-4 text-[#A0A0A0]">
                                <input
                                    type="text"
                                    placeholder="Workspace Name"
                                    value={workspaceName}
                                    onChange={handleNameChange}
                                    disabled={!isEditing}
                                    className="w-full px-4 py-2 bg-inherit focus:outline-none active:outline-none focus:border-primary transition-colors duration-200"
                                />
                            </div>
                            <div
                                className='bg-[#D85C93] border border-[#E27AAB] rounded-md px-6 py-2 cursor-pointer'
                                onClick={handleEditClick}
                            >
                                {isEditing ? 'Save' : 'Edit'}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="text-[#B0B0B0] mb-6 text-lg">
                            Workspaces
                        </div>
                        <div className="">
                            {workspaces.filter(workspace => workspace._id !== workspaceContext._id).map((workspace) => (
                                <div key={workspace.id} className="rounded-md mb-2 w-[548px] bg-[#0F1011] border border-[#2C2E33] flex justify-between items-center gap-2 px-4 py-2 text-[#A0A0A0]">
                                    {workspace.name}
                                    <div
                                        className="hover:text-gray-300"
                                        onClick={() => {/* handle clear */ }}
                                    >
                                        <LuTrash />
                                    </div>
                                </div>
                            ))}
                            <button
                                className="px-2 py-1.5 rounded-md border border-dashboard-dark-400 flex items-center space-x-2 text-dashboard-text-gray"
                            >
                                <HiMiniPlusSmall className="h-4 w-4" />
                                <span>Add New Workspace</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Panel>
        </div>
    );
};

export default WorkspaceSettings;