import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchTestRuns } from '../../Actions/tcActions';
import { MdOutlineArrowBackIos } from "react-icons/md";

const TestRunSelector = () => {
  const [selectedRun, setSelectedRun] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [testRuns, setTestRuns] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const sortByDate = (a, b) => {
    return new Date(b.date) - new Date(a.date);
  };

  const filteredRuns = Array.isArray(testRuns)
    ? testRuns
      .filter(run => !searchQuery || run?.name?.toLowerCase().includes(searchQuery.toLowerCase()))
      .sort(sortByDate)
    : [];

  useEffect(() => {
    const loadTestRuns = async () => {
      setLoading(true);
      try {
        const data = await dispatch(fetchTestRuns());
        console.log(data);
        if (data.testRuns && Array.isArray(data.testRuns)) {
          setTestRuns(data.testRuns);
          setSelectedRun(data.testRuns[0]);
        }
      } catch (error) {
        console.error('Failed to fetch test runs:', error);
        setTestRuns([]);
      } finally {
        setLoading(false);
      }
    };
    loadTestRuns();
  }, [dispatch]);

  return (
    <div className="relative z-20">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="px-3 py-1 text-dashboard-text-graylight rounded border border-dashboard-dark-500 flex items-center justify-between gap-2"
      >
        <span className="truncate">{selectedRun?.name || 'Select Test Run'}</span>
        <MdOutlineArrowBackIos className={`cursor-pointer ${isOpen ? "rotate-90" : "-rotate-90"}`} />
      </button>

      {isOpen && (
        <div className="absolute mt-1 w-80 bg-[#0F1011] border border-[#2C2E33] rounded-md shadow-lg">
          <div className="p-2 border-b border-[#2C2E33]">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search test runs..."
              className="w-full px-3 py-1.5 bg-[#141516] rounded border border-[#2C2E33] text-[#A0A0A0] focus:outline-none focus:border-[#E27AAB]"
            />
          </div>

          <div className="max-h-[250px] overflow-y-auto custom-scrollbar">
            {loading ? (
              <div className="p-4 text-center text-[#A0A0A0]">
                <span className="inline-flex items-center">
                  Loading
                  <span className="inline-flex ml-[2px]">
                    <span className="animate-loadingDot1">.</span>
                    <span className="animate-loadingDot2">.</span>
                    <span className="animate-loadingDot3">.</span>
                  </span>
                </span>
              </div>
            ) : (
              filteredRuns.map((run) => (
                <div
                  key={run.id}
                  onClick={() => {
                    setSelectedRun(run);
                    setIsOpen(false);
                  }}
                  className="p-3 hover:bg-[#141516] cursor-pointer border-b border-[#2C2E33] last:border-0"
                >
                  <div className="font-medium text-white">{run.name}</div>
                  <div className="text-sm text-[#A0A0A0] mt-1">
                    {new Date(run.date).toLocaleString()}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TestRunSelector;