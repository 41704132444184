import axiosInstance from "../axiosInterceptor";

let dburl1 = process.env.REACT_APP_DATABASE_URL;
let test_executor_url = process.env.REACT_APP_TEST_EXECUTOR_URL;
let seq_agent_url = process.env.REACT_APP_SEQ_AGENT_URL;
let json_url = process.env.REACT_APP_JSON_URL || "https://json-schema-gen-7qxc7hlaka-uc.a.run.app";

export const fetchWorkspaceUsers = (workspaceId) => async (dispatch) => {
    try {
        dispatch({
            type: "fetchWorkspaceUsers"
        });

        const response = await axiosInstance.get(`${dburl1}/workspaces/${workspaceId}/users`, {
            withCredentials: true
        });

        dispatch({
            type: "fetchWorkspaceUsersSuccess",
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: "fetchWorkspaceUsersFailure",
            payload: error.message,
        });
    }
};


