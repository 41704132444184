import React from "react";
import DevzeryIcon from "../../icons/devzery-icon.svg";

/*

Not Found component that renders the not found/404 page of the application.

*/
const NotFound = ({message}) => {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center bg-[#080808] text-white">
      <img src={DevzeryIcon} />
      
      {message ? <p className="text-[#DCDCDC] text-center font-semibold mt-20">{message}</p> : 
      <>
      <div className="mt-8 mb-4 text-[40px] font-semibold">404</div>
      <p className="text-[#DCDCDC] text-center">
      The Page you're looking for does not exist.
    </p>
    <p>Please check the URL and try again.</p>
    </>
    }
      

    </div>
  );
};

export default NotFound;