import { getMethodClasses } from "../../../utils/ui";


const MethodPath = ({method, path, extraClasses}) => {
   return <div className={`w-full rounded-md border border-[#2C2E33] py-4 flex items-center ${extraClasses}`}>
       <div className="px-4 border-r border-[#2C2E33]"><span className={"rounded-full px-4 py-1 text-xs " + getMethodClasses(method)}>{method}</span></div>
       <div className="px-4">{path}</div>
   </div>
}


export default MethodPath;
