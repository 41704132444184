
const Dropdown = ({ options }) => {
    return (
        <div className="relative right-0 mt-2 w-48 rounded-md shadow-lg bg-[#1D1E20] ring-1 ring-black ring-opacity-5 z-10">
            <div className="py-1">
                {options.map((option, index) => (
                    <button
                        key={index}
                        className={`w-full text-left px-4 py-2 text-sm text-[${option.textColor}] hover:bg-[#282C33]`}
                        onClick={option.onClick}
                    >
                        {option.label}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Dropdown;
