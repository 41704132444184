import { Button } from "@mui/material";
import { getMethodClasses } from "../../utils/ui";
import { PiPlus, PiCopy } from "react-icons/pi";
import { LuTrash } from "react-icons/lu";
import { copyToClipboard } from "../../utils/common";
import { Fragment, useState, useEffect } from "react";
import SnackbarComp from "../Snackbar/Snackbar";
import Beautify from '../../icons/beautify-icon.svg'
import SuiteDialog from "../Dialog/Suite/SuiteDialog";
import { getResponseBody } from "../../Actions/tcActions";
import { useDispatch } from "react-redux";
import JSONPrettyComp from "../Details/JSONPretty/JSONPretty";
import { MdOutlineArrowBackIos } from "react-icons/md";


const AddAPIModal = ({ width, modalOpen, setModalOpen, apiDetails, setApiDetails, responseBody, headers, setHeaders, handleAddAPI, setLoadingType }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");
    const [methodOpen, setMethodOpen] = useState(false);

    const dispatch = useDispatch();

    const handleHeaderDelete = (index) => {
        let newHeaders = [...headers];
        newHeaders.splice(index, 1);
        setHeaders(newHeaders);
    }

    useEffect(() => {
        if (headers[headers.length - 1][0] !== "" && headers[headers.length - 1][1].length === 1) {
            setHeaders([...headers, ["", ""]]);
        }
    }, [headers[headers.length - 1][1]])

    const handleTestAPI = () => {
        let formattedHeaders = {};
        headers.forEach((header) => {
            if (header[0] !== "" && header[1] !== "") {
                formattedHeaders[header[0]] = header[1];
            }
        });
        // console.log(headers, formattedHeaders, body, JSON.parse(body));
        dispatch(
            getResponseBody({
                body: JSON.parse(apiDetails.body),
                headers: formattedHeaders,
                apiEndpoint: apiDetails.path,
                apiMethod: apiDetails.method,
                serverName: apiDetails.serverName,
            })
        );
        setLoadingType && setLoadingType("Testing API");
    };

    const methods = ["get", "post", "put", "delete", "patch"];

    return <Fragment>
        <SuiteDialog width={width} open={modalOpen}
            body={<div>
                <form onSubmit={handleAddAPI}>
                    <div className="w-full p-6">
                        <div className="flex justify-between items-center mb-8">
                            <div className="text-lg">Add API</div>
                            <div
                                onClick={() => setModalOpen(false)}
                                className="text-white cursor-pointer"
                            >
                                <PiPlus className="rotate-45" size={14} />
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-3">
                            <input required className="bg-inherit rounded-md border border-[#2C2E33] p-3 focus:outline-none active:outline-none" placeholder="Enter the API name" value={apiDetails.name} onChange={(e) => setApiDetails({ ...apiDetails, name: e.target.value })} />
                            <input required className="bg-inherit rounded-md border border-[#2C2E33] p-3 focus:outline-none active:outline-none" placeholder="Enter the server name" value={apiDetails.serverName} onChange={(e) => setApiDetails({ ...apiDetails, serverName: e.target.value })} />
                            <div className="w-full rounded-md border border-[#2C2E33] py-4 flex items-center col-span-2">
                                <div className="px-4 border-r border-[#2C2E33] relative">
                                    <div className="flex items-center gap-4" onClick={() => setMethodOpen(!methodOpen)}>
                                        <span className={"rounded-full px-2 py-1 text-xs outline-none active:outline-none w-[56px] text-center uppercase " + getMethodClasses(apiDetails?.method ?? 'get')}>{apiDetails?.method ?? 'get'}</span>
                                        <MdOutlineArrowBackIos className={"text-[#B0B0B0] cursor-pointer " + (methodOpen ? "rotate-90" : "-rotate-90")} />
                                    </div>
                                    {methodOpen && <div className="w-full absolute top-12 left-0 rounded border border-dashboard-border-500 p-1.5 z-10 bg-dashboard-dark-700">
                                        {methods.map((method) => <div onClick={() => { setApiDetails({ ...apiDetails, method: method }); setMethodOpen(!methodOpen) }} className={`rounded-md cursor-pointer flex justify-center items-center border border-transparent py-1.5 px-2.5 ${apiDetails.method === method && 'bg-dashboard-dark-600 border-dashboard-dark-100'}`}><span className={"rounded-full px-2 py-1 text-xs outline-none active:outline-none w-[70px] text-center uppercase " + getMethodClasses(method)}>{method}</span></div>)}
                                    </div>}
                                </div>
                                <input required value={apiDetails.path} onChange={(e) => setApiDetails({ ...apiDetails, path: e.target.value })} className="px-4 bg-inherit w-full focus:outline-none active:outline-none" placeholder="Enter API endpoint" />
                            </div>
                            <div className="rounded-md border border-[#2C2E33] text-[#A0A0A0] col-span-2">
                                <div className="border-b border-[#2C2E33] ">
                                    <div className={"px-6 py-2 cursor-pointer border-b-2 border-[#E27AAB] text-[#D9D9D9] w-max"}>Headers</div>
                                </div>
                                <div className="max-h-[25vh] overflow-y-auto no-scrollbar">
                                    <div className="grid grid-cols-12 border-b border-[#2C2E33]">
                                        <div className="col-span-1" />
                                        <div className="col-span-4 px-3 py-2 border-x border-[#2C2E33]">Key</div>
                                        <div className="col-span-7 px-3 py-2">Value</div>
                                    </div>
                                    {headers.map((header, index) => <div className="grid grid-cols-12 border-b border-[#2C2E33]">
                                        <div className="col-span-1" />
                                        <input value={header[0]} onChange={(e) => {
                                            const newHeaders = [...headers];
                                            newHeaders[index][0] = e.target.value;
                                            setHeaders(newHeaders);
                                        }} className="col-span-4 bg-inherit px-3 py-2 border-x border-[#2C2E33]" />
                                        <input value={header[1]} onChange={(e) => {
                                            const newHeaders = [...headers];
                                            newHeaders[index][1] = e.target.value;
                                            setHeaders(newHeaders);
                                        }} className="col-span-6 bg-inherit px-3 py-2" />
                                        <div onClick={headers.length === 1 ? () => console.log('well') : () => handleHeaderDelete(index)} className="col-span-1 text-[#494949] flex items-center justify-center border-l border-[#2C2E33]" >
                                            <span className={headers.length > 1 ? "cursor-pointer rounded-md p-1.5 hover:text-[#F87171] hover:bg-[#1D1E20]" : ""}>
                                                <LuTrash />
                                            </span>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                            <div className="rounded-md border border-[#2C2E33] p-2">
                                <div className="flex justify-between items-center">
                                    <div className={"px-4 py-1 cursor-pointer rounded-md border border-[#2C2E33] text-[#A0A0A0]"}>Request</div>
                                    <div className="flex gap-2 items-center">
                                        <img src={Beautify} title="Beautify" className="text-[#494949] w-[16px] h-[16px] cursor-pointer hover:text-[#D9509B]"
                                            onClick={() => {
                                                setApiDetails({ ...apiDetails, body: JSON.stringify(JSON.parse(apiDetails?.body), null, 4) });
                                            }}
                                        />
                                        <PiCopy size={16} className="text-[#494949] cursor-pointer" onClick={() => copyToClipboard(apiDetails?.body ?? {}, setSnackbarOpen, setAlertSeverity, setAlertMessage)} />
                                    </div>
                                </div>
                                <textarea value={apiDetails?.body} onChange={(e) => setApiDetails({ ...apiDetails, body: e.target.value })} placeholder="Add request body" className="bg-inherit p-2 w-full h-[25vh] overflow-y-auto no-scrollbar text-[#B0B0B0] focus:outline-none active:outline-none" />
                            </div>
                            <div className="rounded-md border border-[#2C2E33] p-2">
                                <div className="flex justify-between items-center">
                                    <div className={"px-4 py-1 cursor-pointer rounded-md border border-[#2C2E33] text-[#A0A0A0]"}>Response</div>
                                    <PiCopy size={16} className="text-[#494949] cursor-pointer" onClick={() => copyToClipboard(responseBody?.data ?? {}, setSnackbarOpen, setAlertSeverity, setAlertMessage)} />
                                </div>
                                <div className="h-[25vh] overflow-y-auto no-scrollbar text-[#B0B0B0] p-2">{responseBody ? (
                                    <JSONPrettyComp data={JSON.stringify(responseBody?.data ?? { message: "hi" })} />
                                ) : (
                                    "Test the API to see the response..."
                                )}</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between text-sm px-12 py-4 border-t border-[#2C2E33] items-center mt-6">
                        <div onClick={handleTestAPI} className="cursor-pointer rounded-md bg-[#D9509B] hover:bg-[#CC3F8D] border border-1 border-[#FF86BE] px-4 py-1.5">
                            Test API
                        </div>
                        <div className="flex text-sm">
                            <div
                                onClick={() => setModalOpen(false)}
                                className="cursor-pointer mr-3 rounded-md bg-[#2D2F37] border border-1 border-[#35383E] px-4 py-1.5"
                            >
                                Cancel
                            </div>
                            <Button
                                sx={{
                                    "&.Mui-disabled": {
                                        background: "#222222",
                                        color: "white",
                                        border: "1px solid #222222",
                                    },
                                    borderRadius: "6px",
                                    backgroundColor: "#D9509B",
                                    border: "1px solid #FF86BE",
                                    padding: "6px 16px",
                                    ":hover": {
                                        backgroundColor: "#CC3F8D",
                                    },
                                    color: "white",
                                    textTransform: "unset",
                                    fontWeight: "400",
                                }}
                                type="submit"
                                {...(responseBody
                                    ? { disabled: false }
                                    : { disabled: true })}
                                className="cursor-pointer rounded-md bg-[#D9509B] border border-1 border-[#FF86BE] px-4 py-1.5"
                            >
                                Add API
                            </Button>
                        </div>
                    </div>
                </form>
            </div>} />
        <SnackbarComp open={snackbarOpen} severity={alertSeverity} message={alertMessage} handleClose={() => setSnackbarOpen(false)} />
    </Fragment>
}

export default AddAPIModal;