import { createReducer } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    workspaceUsers: [],
    error: null
};

export const workspaceReducer = createReducer(initialState, (builder) => {
    builder
    .addCase('fetchWorkspaceUsers', (state) => {
        state.loading = true;
        state.error = null;
    })
    .addCase('fetchWorkspaceUsersSuccess', (state, action) => {
        state.loading = false;
        state.workspaceUsers = action.payload.users;
    })
    .addCase('fetchWorkspaceUsersFailure', (state, action) => {
        state.loading = false;
        state.error = action.payload;
    });
});