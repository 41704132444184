import { useState, useRef, Fragment } from "react";
import { Popover } from "@mui/material";
import { PiCopy, PiPlus } from "react-icons/pi";
import { RiSubtractFill } from "react-icons/ri";
import { AiOutlineImport } from "react-icons/ai";
import ReqResDisplay from "./ResResDisplay/ReqResDisplay";

const RequestResponse = ({ addedApis, reqBody, resContent, setReqBody, setResContent, currentApi, setAddedApis }) => {
    const [toggleState, setToggleState] = useState(1);
    const [importAPI, setImportAPI] = useState('default');
    const [selected, setSelected] = useState({ value: '', ind: 0 })
    const [extra, setExtra] = useState({});
    const [extraRes, setExtraRes] = useState({});
    const [popOpen, setPopOpen] = useState({ value: false, ind: 0, type: 'req' });
    const anchorEl = useRef(null);
    const resAnchorEl = useRef(null);
    const resRef = useRef(null);
    const reqRef = useRef(null);

    let apiInd = addedApis.findIndex((api) => api.id === currentApi.id);


    const handleImport = () => {
        if (popOpen.type === 'req') {
            let temp = { ...extra };
            let apitemp = { ...temp[apiInd] };
            apitemp[popOpen.ind] = typeof (selected.value[1]) === 'string' || typeof (selected.value[1]) === 'boolean' || typeof (selected.value[1]) === 'number' ? `"${selected.value[1]}"` : JSON.stringify(selected.value[1]);
            temp[apiInd] = apitemp;
            setExtra(temp);
            let ind = addedApis.findIndex((api) => api.id === currentApi.id);
            let path = addedApis[importAPI].variables.find((variable) => variable.name === selected.value[0]).path;
            let apiTemp = {
                ...addedApis[ind], replace_variables: [...addedApis[ind].replace_variables, {
                    name: selected.value[0],
                    path: path
                }]
            };
            addedApis[ind] = apiTemp;
            setAddedApis(addedApis);
        } else {
            let temp = { ...extraRes };
            let apitemp = { ...temp[apiInd] };
            apitemp[popOpen.ind] = typeof (selected.value[1]) === 'string' || typeof (selected.value[1]) === 'boolean' || typeof (selected.value[1]) === 'number' ? `"${selected.value[1]}"` : JSON.stringify(selected.value[1]);
            temp[apiInd] = apitemp;
            setExtraRes(temp);
            let ind = addedApis.findIndex((api) => api.id === currentApi.id);
            let path = addedApis[importAPI].variables.find((variable) => variable.name === selected.value[0]).path;
            let apiTemp = {
                ...addedApis[ind], replace_variables: [...addedApis[ind].replace_variables, {
                    name: selected.value[0],
                    path: path
                }]
            };
            addedApis[ind] = apiTemp;
            setAddedApis(addedApis);
        }
        setPopOpen({ ...popOpen, value: false });
        
    }

    const renderObject = (data, indent = 0) => {
        return Object.entries(typeof data === "string" ? JSON.parse(data) : data).map(([key, value], ind) => (
            <div className="">
                <div style={{ marginLeft: `${indent * 10}px` }} className="text-wrap break-words w-full cursor-pointer ">
                    {value !== null && ((Array.isArray(value) && value.length > 0) || (value.constructor === Object && Object.keys(value).length > 0)) ? (
                        // If value is an object, recursively render it
                        <div className="flex flex-col w-full ">
                            <span className="py-0.5 overflow-x-auto no-scrollbar px-2 py-1">{key}: {Array.isArray(value) ? '[' : '{'}</span>
                            <div className="flex flex-col">{renderObject(value, indent + 1)}</div>
                        </div>
                    ) : (
                        // Otherwise, display the key-value pair
                        <div className={"group flex justify-between items-center hover:bg-dashboard-dark-700 px-2 py-1 rounded w-full " + ((key + value) === selected.ind ? " border border-[#E27AAB]" : "")} onClick={() => setSelected({ value: [key, value], ind: (key + value) })}>
                            <span className="py-0.5 overflow-x-auto no-scrollbar">{key}: {(value && Array.isArray(value)) ? "[]" : (value && typeof value === "object") ? "{}" : String(value) ?? ''}</span>
                            <div className="hidden group-hover:block">{(key + value) === selected.ind ? <RiSubtractFill className="bg-[#1D1E20] p-1 flex items-center justify-center rounded-sm" size={22} /> : <AiOutlineImport className="bg-[#494949] cursor-pointer p-1 flex items-center justify-center rounded-sm" size={16} />}</div>
                        </div>
                    )}
                </div>
            </div>)
        );
    };

    return <div>
        <div className="flex gap-2 items-center mb-4">
            <div onClick={() => setToggleState(1)} className={"px-4 py-1 cursor-pointer rounded-md " + (toggleState === 1 ? "bg-[#1D1E20] text-white" : "border border-[#2C2E33] text-[#A0A0A0]")}>Request</div>
            <div onClick={() => setToggleState(2)} className={"px-4 py-1 cursor-pointer rounded-md " + (toggleState === 2 ? "bg-[#1D1E20] text-white" : "border border-[#2C2E33] text-[#A0A0A0]")}>Response</div>
        </div>
        <div className="rounded-md border border-[#2C2E33] p-4 w-full">
            <div
                ref={toggleState === 1 ? reqRef : resRef}
                className="p-3 w-full h-[26vh] overflow-scroll no-scrollbar bg-[#08040A] focus:outline-none active:outline-none relative"
                type="text"
            >
                <PiCopy size={16} className="text-[#494949] absolute top-4 right-4 cursor-pointer" onClick={() => navigator.clipboard.writeText(toggleState === 1 ? reqBody : resContent)} />
                {toggleState === 1 ? reqBody && reqBody.split("\n").map((line, index) => <ReqResDisplay
                    index={index}
                    line={line}
                    reqBody={reqBody}
                    setReqBody={setReqBody}
                    anchorEl={anchorEl}
                    extra={extra}
                    setPopOpen={setPopOpen}
                    popOpen={popOpen}
                    key={index}
                    apiInd={apiInd}
                />
                ) : resContent && resContent.split("\n").map((line, index) => <ReqResDisplay
                    index={index}
                    line={line}
                    resContent={resContent}
                    setResContent={setResContent}
                    resAnchorEl={resAnchorEl}
                    extraRes={extraRes}
                    setPopOpen={setPopOpen}
                    popOpen={popOpen}
                    key={index}
                    apiInd={apiInd}
                />
                )}
            </div>
        </div>
        <Popover
            open={popOpen.value}
            onClose={() => setPopOpen({ ...popOpen, value: false })}
            anchorEl={popOpen.type === 'req' ? anchorEl.current : resAnchorEl.current}
            className="no-scrollbar"
            slotProps={{
                paper: {
                    style: {
                        width: "40vw",
                        backgroundColor: "#0F1011",
                        color: "#B0B0B0",
                        boxShadow: "none",
                        borderRadius: "12px",
                        border: "1px solid #3D3D3D",
                        padding: "1rem",
                        maxHeight: "50vh",
                        overflowY: "scroll",
                        display: "flex",
                        flexDirection: "column",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                        "&::WebkitScrollbar": {
                            display: "none",
                        }
                    },
                }
            }}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: popOpen.type === 'res' ? "bottom" : "top",
                horizontal: "left",
            }}
            anchorPosition={
                {
                    top: 0,
                    left: -500
                }
            }
        >
            {<Fragment>
                <div
                    onClick={() => setPopOpen({ ...popOpen, value: false })}
                    className="origin-center rotate-45 cursor-pointer text-[#B0B0B0] self-end"
                >
                    <PiPlus size={20} />
                </div>
                <div className="font-semibold my-2">Select API</div>
                <select className="bg-[#0F1011] px-3 py-2 py-0.5 rounded-md border border-[#2C2E33]" value={importAPI} onChange={(e) => setImportAPI(e.target.value)}><option disabled="disabled" value={'default'}>Select an API</option>{addedApis.map((api, index) => index < addedApis.indexOf(currentApi) ? <option value={index}>{api.name}</option> : null)}</select>
                {importAPI !== 'default' ? <div className="font-semibold my-2">Response Content</div> : null}
                {importAPI !== 'default' ? <div className="rounded-md bg-[#080808] border border-[#2C2E33] p-4 overflow-auto no-scrollbar">
                    {addedApis[importAPI].response.content ? renderObject(addedApis[importAPI].response.content) : null}
                </div> : null}
                <div className={"px-3 py-1 text-white rounded-md max-w-fit self-end justify-self-end mt-4 " + (importAPI === 'default' ? "bg-[#222222]" : "bg-[#D9509B] hover:bg-[#C0408C] cursor-pointer")} onClick={importAPI === 'default' ? () => console : handleImport}>Import</div>
            </Fragment>}

        </Popover>
    </div>
}

export default RequestResponse;