import { useState } from "react";
import { copyToClipboard } from '../../../utils/common';
import SnackbarComp from "../../Snackbar/Snackbar";
import { PiCopy } from "react-icons/pi";
import { BsArrowsAngleExpand, BsArrowsAngleContract } from "react-icons/bs";
import JSONPrettyComp from "../JSONPretty/JSONPretty";

const ReqRes = ({ config, duration, status, expand, setExpand, type }) => {
    const [reqresToggle, setReqresToggle] = useState(0);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState("");

    return <div className="w-full">
        <div className="flex justify-between items-center mt-6 mb-4">
            <div className="flex gap-3 items-center">
                {config.map((item, index) => <div onClick={() => setReqresToggle(index)} className={`px-4 py-1 cursor-pointer rounded border ${reqresToggle === index ? "border-dashboard-dark-600 bg-dashboard-dark-600 text-white" : "border-dashboard-border-500 text-dashboard-text-gray"}`}>{item.name}</div>)}
            </div>
            {duration && <div className="flex gap-3 items-center text-paragraph font-normal">
                <div className="rounded py-0.5 px-1.5 border border-[#2C2E33] text-dashboard-text-gray">{duration / 1000} ms</div>
                <div className={"py-0.5 px-1.5 rounded " + (status < 300 ? "bg-[#112313] text-[#65DC8D]" : "bg-[#240F10] text-[#F87171]")}>{status}</div>
            </div>}
        </div>
        <div className="rounded-md border border-[#2C2E33] p-3 h-[30vh] overflow-y-auto no-scrollbar relative">
            <div className='text-[#494949] absolute top-4 right-4 flex gap-3 items-center'>
                <PiCopy size={16} className="cursor-pointer" onClick={() => copyToClipboard(typeof config[reqresToggle]?.data == "string" ? config[reqresToggle]?.data : JSON.stringify(config[reqresToggle]?.data), setSnackbarOpen, setAlertSeverity, setAlertMessage)} />
                {expand && (expand.val ? <BsArrowsAngleContract className='cursor-pointer' onClick={() => setExpand({ type: '', val: false })} /> : <BsArrowsAngleExpand onClick={() => setExpand({ type, val: true })} className="cursor-pointer" />)}
            </div>
            <JSONPrettyComp data={config[reqresToggle]?.data ?? {}} />
        </div>
        <SnackbarComp open={snackbarOpen} severity={alertSeverity} message={alertMessage} handleClose={() => setSnackbarOpen(false)} />

    </div>
}

export default ReqRes;
